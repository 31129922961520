/* global chrome */
import React, { useEffect } from "react";
import { HiArrowLeft } from "react-icons/hi2";
import {
  IoIosNotificationsOutline,
  IoIosArrowForward,
  IoIosLogOut,
} from "react-icons/io";
import config from "../../config.json"

import { FaRegUserCircle, FaUserCog } from "react-icons/fa";
import { FaGift, FaRegShareFromSquare, FaUserPlus } from "react-icons/fa6";
import { CiSettings, CiWallet } from "react-icons/ci";
import { TbFileReport } from "react-icons/tb";
import { BsQrCodeScan } from "react-icons/bs";
import { MdOutlineContactSupport } from "react-icons/md";
import { GoCodeReview } from "react-icons/go";
import "./Profile.css";
import Draggable from "react-draggable";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ClearSession } from "../../external_js/html_popup";
import { getData } from "../../js/helper";
import { useDragContext } from "../../App";

export const Profile = () => {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const { dragPosition, handleDrag } = useDragContext();

  useEffect(() => {
    getData().then((data) => {
      console.log("data from chrome storage", data);
      setUsername(data.apiTokenData.data.user_name);
      setUserId(data.apiTokenData.data.user_id);
    });
  }, []);
  //  profile.accessToken[1].data.user_name);

  return (
    <Draggable position={dragPosition} onDrag={handleDrag}>
      <div className="profile-page">
        <div className="app-header">
          {/* back icon */}
          <Link to={"/trade"}>
            <HiArrowLeft className="header-icon" size={25} />
          </Link>

          <h1 className="page-title">Profile</h1>
          {/* notification icon */}
          <IoIosNotificationsOutline className="header-icon" size={25} />
        </div>

        {/* user name and icon details */}
        <div className="user-details">
          {/* left div with user-icon and name */}
          <div className="user-name-container">
            <FaRegUserCircle className="user-icon" size={30} />
            <div className="user-name">
              <h4>{username}</h4>
              <p>{userId}</p>
            </div>
          </div>
          <IoIosArrowForward className="forward-icon" size={25} />
        </div>
        {/*End of user name and icon details */}

        {/* cards container */}
        <div className="cards-container">
          {/* signle card */}
          <Link to={"/funds"}>
            <div className="service-card">
              <CiWallet className="service-icon" size={25} />
              <p>Funds</p>
            </div>
          </Link>

          <a
            href="https://www.kredallino.tech"
            target="_blank"
            rel="noreferrer">
            <div className="service-card">
              <FaUserCog className="service-icon" size={25} />
              <p>Services</p>
            </div>
          </a>
          <Link to={"/change-log"}>
            <div className="service-card">
              <CiSettings className="service-icon" size={25} />
              <p>settings</p>
            </div>
          </Link>

          <a
            href="https://www.kredallino.tech/contacts.html"
            target="_blank"
            rel="noreferrer">
            <div className="service-card">
              <MdOutlineContactSupport className="service-icon" size={25} />
              <p>Support</p>
            </div>
          </a>
        </div>

        {/* some more cards container */}
        <div className="tutorial-cards">
          <div className="tutorial-card">
            <div className="left-side">
              <BsQrCodeScan size={25} />
              <p>Login to desktop web</p>
            </div>
            <IoIosArrowForward size={25} />
          </div>
          <div className="tutorial-card">
            <div className="left-side">
              <BsQrCodeScan size={25} />
              <p>How to? videos</p>
            </div>
            <FaRegShareFromSquare size={25} />
          </div>
          <div className="tutorial-card">
            <div className="left-side">
              <GoCodeReview size={25} />
              <a href="mailto:support@kredkonect.com">
                <p>Submit Feedbacks</p>
              </a>
            </div>
            <FaRegShareFromSquare size={25} />
          </div>
        </div>
        <div className="tutorial-card">
          <div className="left-side">
            <FaUserPlus size={25} />
            <p>Add account</p>
          </div>
        </div>

        <div className="tutorial-card">
          <div
            className="left-side"
            onClick={() => {
              ClearSession();
              navigate("/");
            }}>
            <IoIosLogOut size={25} className="logout-icon" />
            <p>Log out</p>
          </div>
        </div>
      </div>
    </Draggable>
  );
};
