import { createAction } from "redux-actions";
import _ from "lodash"

export const setFundsRdxConst = "SET_FUNDS";

export const setFundsAction = createAction(
  setFundsRdxConst,
  (payload) => payload
);

export const setFundsReducer = (state = { funds: {} }, action) => {
  switch (action.type) {
    case setFundsRdxConst:
      return { ...state, ..._.cloneDeep(action.payload) };
    default:
      return state;
  }
};

// Path: react_app/src/redux/funds/setFundsAction.js
