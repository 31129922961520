import { createAction } from "redux-actions";
export const setOptionChainsRdxConst = "SET_OPTION_CHAINS";

export const setOptionChainsAction = createAction(setOptionChainsRdxConst, (payload) => payload);

export const setOptionChainsReducer = (state = [], action) => {
    switch (action.type) {
        case setOptionChainsRdxConst:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

// Path: react_app/src/redux/funds/setFundsAction.js