import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import "./Order.css";
import { TabContent } from "./ShowTabContent.jsx";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import { useDragContext } from "../../App.js";
import { NavigationItems } from "../Navigation/NavigationItems.jsx";

export default function OrderComponent() {
  const [currentTab, setCurrentTab] = useState("Open");
  const { dragPosition, handleDrag } = useDragContext();

  const order = useSelector((state) => state.order);
  console.log("order  from redux", order);

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <Draggable position={dragPosition} onDrag={handleDrag}>
      <div className="orders-page">
        <div className="page-container">
          {/* header */}
          <div className="orders-header">
            <div className="header-items">
              {/* items */}
              <div className="header-item">
                <h4>NIFTY</h4>
                <p className="ltp-26000">
                  20966.40 <span> -30.70(-0.15%)</span>
                </p>
              </div>
              <div className="header-item">
                <h4>BANKNIFTY</h4>
                <p className="ltp-26009">
                  20966.40 <span> -30.70(-0.15%)</span>
                </p>
              </div>
              <FaChevronDown className="header-icon" size={18} />
            </div>

            {/* tabs */}
            <div className="orders-tabs">
              <div
                className={`tab ${currentTab === "Open" ? "active" : ""}`}
                onClick={() => handleTabChange("Open")}>
                <p>Open</p>
              </div>

              <div
                className={`tab ${currentTab === "Closed" ? "active" : ""}`}
                onClick={() => handleTabChange("Closed")}>
                <p>Closed</p>
              </div>
              {/* <div
                className={`tab ${currentTab === "Basket" ? "active" : ""}`}
                onClick={() => handleTabChange("Basket")}
              >
                <p>Basket</p>
              </div> */}
            </div>
          </div>
          {/* This component handles the tabs content based on the currentTab */}
          <TabContent currentTab={currentTab} />
        </div>
        <NavigationItems />
      </div>
    </Draggable>
  );
}
