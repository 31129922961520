import React from "react";
import "./pnl.css";
import { useSelector } from "react-redux";

export const Pnl = () => {
  var PnlObject = useSelector((state) => state.pnl);

  console.log("day pnl from redux", PnlObject); // Assuming positions contains the pnl data
  PnlObject = PnlObject.pnl;
  let livePnl = 0;

  Object.getOwnPropertyNames(PnlObject).forEach((element) => {
    console.log("element from pnl object", PnlObject[element]);
    livePnl += PnlObject[element].pnl;
  });

  // console.log("PNL", livePnl, "percentage ", percentagePnl);
  // console.log(
  //   "Hi! 👋 from pnl compoent , to check how many time this is rendered and when ( mostly on websocket updated , frequently )"
  // );

  return (
    <div className="trade-pnl-container">
      <div className="pnl">
        <span>PNL</span>
        <span style={{ color: livePnl < 0 ? "red" : "green" }}>
          {livePnl.toFixed(2)}
        </span>
      </div>
    </div>
  );
};
