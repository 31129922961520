export const formatDate = (dateString) => {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formattedDate = `${parseInt(day, 10)} ${
    months[parseInt(month, 10) - 1]
  } ${year}`;
  return formattedDate;
};

export const formatIndianNumber = (number) => {
  if (number >= 10000000) {
    return (number / 10000000).toFixed(1) + "cr";
  } else if (number >= 100000) {
    return (number / 100000).toFixed(1) + "L";
  }
  return number.toString();
};

export const formatTime = (date) => {
  const parseDate = new Date(date);

  const hours = parseDate.getHours();
  const minute = parseDate.getMinutes();
  const seconds = parseDate.getSeconds();

  const formattedTime = `${hours}:${minute}:${seconds}`;
  return formattedTime;
};
