import React, { useEffect, useState } from "react";
import { TbFocus2 } from "react-icons/tb";
import { FaArrowLeftLong, FaFilterCircleDollar } from "react-icons/fa6";
import "./OptionChain.css";
import { formatIndianNumber, formatDate } from "../../utils.js";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSearchResultsAction } from "../../redux/searchResults/setSearchResultsAction.js";
import { IoSearchOutline } from "react-icons/io5";
import Draggable from "react-draggable";
import { useDragContext } from "../../App.js";

export const OptionChainComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const optionsDataRdx = useSelector((state) => state.optionChain.optionChain);
  // console.log("optionsData from redux", optionsDataRdx);
  const expiry = optionsDataRdx?.expDates;
  const optionsData = optionsDataRdx?.optionData;

  const optionLivePrice = optionsDataRdx?.parentStockData?.livePrice;

  const [currentTab, setCurrentTab] = useState("LTP");
  const [niftyLTP, setNiftyLTP] = useState();
  const [bankNiftyLTP, setBankNiftyLTP] = useState();
  const { dragPosition, handleDrag } = useDragContext();

  const setLTP = () => {
    window.asthaApi
      .quote("NSE_EQ", "26009", "ltp")
      .then((data) => {
        setNiftyLTP(data["NSE_EQ-26009"]?.last_trade_price);
      })
      .catch((err) => console.log(err));
    window.asthaApi
      .quote("NSE_EQ", "26000", "ltp")
      .then((data) => {
        setBankNiftyLTP(data["NSE_EQ-26000"]?.last_trade_price);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    // setLTP();
  }, []);

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  const handleDateChange = async (e) => {
    const selectedExpiryData = parseInt(e.target.value);
    await window.asthaApi.getOptionChain("BANKNIFTY", selectedExpiryData);
  };

  function filterFields(originalObject, optionType) {
    console.log("option type", optionType);
    const filteredObject = {
      _source: {
        sSecurityDesc: originalObject[optionType].securityDesc,
        ExchangeSegment: "NSE_FO",
        nToken: originalObject[optionType].token,
        quantity: originalObject[optionType].lotSize,
      },
    };
    return filteredObject;
  }

  const handleSelectOptionChainRow = (row, optionType) => {
    console.log("selected option", row);
    const filterCard = filterFields(row, optionType);
    dispatch(setSearchResultsAction({ selectSearchResult: filterCard }));
    navigate("/trade");
  };

  const livePriceIndex = optionsData?.findIndex(
    (option) => option.CE.strikePrice >= optionLivePrice
  );

  const optionsToShow = optionsData?.slice(
    Math.max(0, livePriceIndex - 5),
    livePriceIndex + 5
  );

  console.log(
    "strikePrice to show less than 5 and more than 5 livePrice",
    optionsToShow
  );

  if (optionsToShow) {
    optionsToShow.forEach((option) => {
      window.wire?.subscribe("NSE_FO", option.PE.token, "full");
      window.wire?.subscribe("NSE_FO", option.CE.token, "full");
    });
  }

  return (
    <Draggable position={dragPosition} onDrag={handleDrag}>
      <div className="option-chain-page">
        <div className="page-container">
          {/* option-chain header */}
          <div className="option-chain-header">
            <Link to={"/trade"}>
              <FaArrowLeftLong className="back-icon" />
            </Link>

            {/* items */}
            <div
              className="header-item"
              onClick={() => {
                window.asthaApi.getOptionChain("NIFTY");
              }}>
              <h4>NIFTY</h4>
              <p className="ltp-26000"> {"LTP Nifty"}</p>
            </div>
            <div
              className="header-item"
              onClick={() => {
                window.asthaApi.getOptionChain("BANKNIFTY");
              }}>
              <h4>BANKNIFTY</h4>
              <p className="ltp-26009">{"LTP BANK"}</p>
            </div>
            <TbFocus2 className="focus-icon" size={18} />
          </div>
          {/* secondary header */}
          <div className="secondary-header">
            {/* tabs */}
            <div className="tabs">
              <button className="tab" onClick={() => handleTabChange("LTP")}>
                LTP
              </button>
              <button className="tab" onClick={() => handleTabChange("GREEKS")}>
                Greeks
              </button>
            </div>

            {/* expiry dropdown */}
            <select
              className="expiry-select-dropdown"
              onChange={handleDateChange}>
              {expiry.map((date, index) => (
                <option value={date} key={index}>
                  {formatDate(date.toString())}
                </option>
              ))}
            </select>

            {/* filter */}
            <FaFilterCircleDollar size={25} className="filter-icon" />
          </div>

          {/* stock search */}
          <div className="option-search-input-div">
            <Link to={"/search-results"}>
              <input type={"text"} placeholder="Search shares, stocks, .." />
            </Link>
            <IoSearchOutline className="search-icon" size={20} />
          </div>

          {/* call put container */}
          {currentTab === "LTP" ? (
            <div key={"table LTP"}>
              <div className="call-put-container">
                <p>Call</p>
                <p>Put</p>
              </div>
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>OI/% Change</th>
                      <th>Call Price</th>
                      <th>Strike Price</th>
                      <th>Put Price</th>
                      <th>OI% Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    {optionsToShow && optionsToShow.length > 0 ? (
                      optionsToShow?.map((option, index) => (
                        <tr key={index} className="table-row">
                          <td
                            className={`green-border`}
                            onClick={() =>
                              handleSelectOptionChainRow(option, "CE")
                            }>
                            {formatIndianNumber(option.CE.openInterest)}
                          </td>
                          <td
                            className={`green-border ltp-${option.CE.token}`}
                            onClick={() =>
                              handleSelectOptionChainRow(option, "CE")
                            }>
                            {option.CE.strikePrice / 100}
                          </td>

                          <td>{option.strikePrice / 100}</td>

                          <td
                            className={`red-border`}
                            onClick={() =>
                              handleSelectOptionChainRow(option, "PE")
                            }>
                            {option.PE.strikePrice / 100}
                          </td>
                          <td
                            className={`red-border ltp-${option.PE.token}`}
                            onClick={() =>
                              handleSelectOptionChainRow(option, "PE")
                            }>
                            {option.PE.openInterest.toString().slice(0, 2)}%
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>No Data Found , Login Again </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div key={"GREEKS"}>
              <div className="call-put-container">
                <p className="call-text">Call</p>
                <p>Put</p>
                <p>Call & Put Greeks</p>
              </div>
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Delta</th>
                      <th className="greeks-strike-price">Strike</th>
                      <th className="greeks-iv">IV</th>
                      <th>Delta</th>
                      <th>Theta</th>
                      <th>Vega</th>
                      <th>Gamma</th>
                    </tr>

                  </thead>
                  <tbody>
                    {optionsToShow && optionsToShow.length > 0 ? (
                      optionsToShow?.map((option, index) => (
                        <tr key={index}>
                          <td
                            onClick={() =>
                              handleSelectOptionChainRow(option, "CE")
                            }>
                            {option.CE.greeks.delta.toString().slice(0, 3)}
                          </td>
                          <td
                            className="greeks-strike-price"
                            onClick={() =>
                              handleSelectOptionChainRow(option, "CE")
                            }>
                            {option.CE.strikePrice / 100}
                          </td>
                          <td
                            className="greeks-iv"
                            onClick={() =>
                              handleSelectOptionChainRow(option, "CE")
                            }>
                            {option.CE.greeks.iv.toString().slice(0, 4)}
                          </td>
                          <td
                            onClick={() =>
                              handleSelectOptionChainRow(option, "PE")
                            }>
                            {option.PE.greeks.delta.toString().slice(0, 4)}
                          </td>
                          <td
                            onClick={() =>
                              handleSelectOptionChainRow(option, "PE")
                            }>
                            {option.PE.greeks.theta.toString().slice(0, 4)}
                          </td>
                          <td
                            onClick={() =>
                              handleSelectOptionChainRow(option, "PE")
                            }>
                            {option.PE.greeks.vega.toString().slice(0, 3)}
                          </td>
                          <td
                            onClick={() =>
                              handleSelectOptionChainRow(option, "PE")
                            }>
                            {option.PE.greeks.gamma.toString().slice(0, 3)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>No Data Found  </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </Draggable>
  );
};


