/* global chrome */
/* global $ */
/* global wire */
// class for asthatrade api ...
// import { VortexFeed } from "@asthatrade/jsvortex";
// import { Constants } from "@asthatrade/jsvortex";
import { setProfileAction } from "../redux/profile/setProfileAction";
import { setPnlAction } from "../redux/pnl/setPnlAction";
import startWebsocket from "../utils/websocket";
import { setFundsAction } from "../redux/funds/setFundsAction";
import { setOrderBooksAction } from "../redux/orderBook/setOrderBookAction";
import { setPositionsAction } from "../redux/positions/setPositionsAction";
import { setHoldingsAction } from "../redux/holdings/setHoldingAction";
import { setOptionChainsAction } from "../redux/optionChains/setOptionChainAction";
import { appStore } from "../redux/store";
import { message } from "antd";
import { Message } from "./eventAdder";
import * as helper from "./helper";
import { setSearchResultsAction } from "../redux/searchResults/setSearchResultsAction";
import { useNavigate } from "react-router-dom";
import { setNotificationsAction } from "../redux/notifications";
var VortexAPI = require("@asthatrade/jsvortex").VortexAPI;

var VortexFeed = require("@asthatrade/jsvortex");
var Constants = require("@asthatrade/jsvortex");
var JSONbig = require("json-bigint");
var client = new VortexAPI("api_secret", "application_id");
// export const API_BASE_URL = "https://tradex.kredallino.tech/"
// export const API_BASE_URL = 'http://127.0.0.1:8000/'
export const API_BASE_URL = "https://ironedge-api.shubhyadav.tech/"
console.log("API_BASE_URL", API_BASE_URL)
export default class AsthaApi {
    constructor(authToken, webAccessToken) {
        // this.baseUrl = "https://vortex.restapi.asthatrade.com";
        this.baseUrl = "https://vortex.trade.rupeezy.in";
        this.optionChainUrl = this.baseUrl + "/strategies/option_chain?exchange={exchange}&symbol={symbol}&expiry_date={expiry_date}&greeks={greeks}";
        this.orderUrl =
            "https://vortex.restapi.asthatrade.com/orders?limit={limit}&offset={offset}";
        this.placeOrderUrl =
            "https://vortex.trade.rupeezy.in/orders/regular";
        // this.placeOrderUrl = this.baseUrl + "/orders/regular"
        this.modifyOrderUrl =
            this.baseUrl + "/orders/regular/{exchange}/{order_id}"; // cancel url is same
        this.positionsUrl = this.baseUrl + "/portfolio/positions";
        this.holdingUrl = this.baseUrl + "/portfolio/holdings";
        this.instrumentUrl = this.baseUrl + "/data/instruments";
        this.quoteUrl =
            this.baseUrl + "/data/quotes?q={exchange}-{token}&mode={mode}";
        this.historicalUrl =
            this.baseUrl +
            "/data/history?exchange={exchange}&token={token}&to={to}&from={from}&resolution={resolution}";
        this.marginsUrl = this.baseUrl + "/margins/basket"; // for basket order margin required
        this.marginUrl = this.baseUrl + "/margins/order"; // for single order margin required
        this.authToken = "Bearer " + authToken;
        this.webSocketUrl = `wss://feed.asthatrade.com:4517/?auth_token=${authToken}`;
        this.webAccessToken = "Bearer " + webAccessToken;
    }

    async fetchCalls(
        url,
        method,
        payload,
        headers = true,
        accept = "*/*",
        ContentType = "application/json",
        returnJson = true
    ) {
        // method used to make api calls
        // const authToken = appStore.getState().profile.accessToken;
        var token = ""
        try {
            token = await helper.getData("access_token")
        }
        catch (error) {
            console.log("Error in getting token from storage", error)
        }
        console.log("token from storage", token)
        this.authToken = "Bearer " + token
        console.log("this.auth token", this.authToken)
        let requestOptions = {
            method: method,
            headers: {
                Authorization: this.authToken,
                "content-type": ContentType,
            },
            body: payload,
        };

        if (method !== "GET" && method !== "HEAD") {
            // console.log("Not Get and Head Method")
            requestOptions.body = JSON.stringify(payload);
        } else if (method === "GET" || method === "HEAD") {
            delete requestOptions.body;
        }
        let response;

        try {
            console.log("request option", requestOptions, "url", url);
            response = await fetch(url, requestOptions);
            if (!response.ok) {
                alert(response.status);
                throw new Error(
                    `HTTP error! Status: ${response.status} Server Response: ${response.statusText}`
                );
            }
            // const responseData = await parseResponse(response);
            if (returnJson) {
                response = await response.json();
            }
            console.log("Response", response);
            return response;
        } catch (error) {
            console.log("Network Error", error);
            return { status: "error", message: "Error During Fetching Data" };
        }
    }

    async validateToken(access_token = "") {
        // method used to validate the access token
        if (access_token === "") {
            console.log("validating local storage token");
            // this will validate token given at the time of class initialization
            // which can also be the same as local storage token
            let storedToken = await this.getOrders();
            var Response = await this.getFunds();
            console.log("getFunds for token check Response", Response);
            if (Response?.nse) {
                this.getFunds()
                this.getOrders()
                this.holdings()
                startWebsocket(this.authToken.replace("Bearer ", ""))
                this.getOptionChain("NIFTY")
                this.positions(true) // subscribe to positions for websocket
                return true

            }
            else {
                return false
            }
        } else {
            // this will validate the token given at the time of login
            console.log("validating current access token");
            let storedToken = await this.getToken(access_token);
            var Response = await this.getFunds();
            console.log(
                "getFunds Respon se for currect access token check",
                Response
            );
        }
        if (Boolean(Response?.nse)) {
            console.log("session validation passed");
            helper.ShowUsername();
            helper.updateStatus("Online");

            return true;
        } else {
            console.log("session validation failed");
            alert("Session Expired Please Login Again");
            helper.updateStatus("Offline");
            return false;
        }
    }

    async getFunds() {
        // method of fethching funds from asthatrade api
        let url = this.baseUrl + "/user/funds"; // limit 1/sec
        let response = await this.fetchCalls(url, "GET", {});
        console.log("funds", response);
        appStore.dispatch(setFundsAction({ funds: response }));
        return response;
    }

    async getNotifications() {
        const res = await fetch(
            "https://tradex-wud6.onrender.com/notification/kredkonnect"
        );
        const data = await res.json();
        console.log("Notification data ", data);
        // log notification's from redux store here 
        const notifications = appStore.getState().notifications;
        console.log("Notification state from Redux store:", notifications);
        appStore.dispatch(setNotificationsAction(notifications));
    };

    async getOrders(limit = 999999, offset = 1) {
        // method for getting orders from asthatrade api
        this.orderUrl = this.orderUrl.replace("{limit}", limit);
        this.orderUrl = this.orderUrl.replace("{offset}", offset);
        console.log("order url", this.orderUrl);
        let data = await this.fetchCalls(this.orderUrl, "GET");
        // console.log("order data fech data", data)
        appStore.dispatch(setOrderBooksAction({ orders: data }));
        return data;
    }

    async placeOrder(orderData) {
        console.log("place order from quick order window", orderData)
        // method used for placing order
        if (orderData["variety"] === "SL" || orderData["variety"] === "SL-MKT") {
            console.log("SL order data");
            orderData["trigger_price"] = orderData["price"];
        }

        let res = await this.fetchCalls(this.placeOrderUrl, "POST", orderData);
        console.log("order placement response", res);
        this.getOrders();
        this.getFunds();
        return res;
    }

    async modifyOrder(exchange, order_id, orderData) {
        // method used for modifying order
        let url = this.modifyOrderUrl
            .replace("{exchange}", exchange)
            .replace("{order_id}", order_id);
        let data = await this.fetchCalls(url, "PUT", orderData);
        console.log("modify order data", data);
        return data;
    }

    async cancelOrder(exchange, order_id) {
        // method usd for modifying order
        let url = this.modifyOrderUrl
            .replace("{exchange}", exchange)
            .replace("{order_id}", order_id);
        console.log("cancel order ur", url);
        let data = await this.fetchCalls(url, "DELETE");
        console.log("cancel order data", data);
        message.success(data.message)
        return data;
    }

    async positions(susbcribe_websocket = false) {
        // method used for getting positions
        let positionData = await this.fetchCalls(this.positionsUrl, "GET");
        console.log("position data", positionData);
        positionData.data.day.forEach((element) => {
            window.wire.subscribe(element.exchange, element.token, "full")
            console.log("suscribed to from position ", element.exchange, element.token)
        })
        appStore.dispatch(setPositionsAction(positionData));
        if (susbcribe_websocket) {
            // this is to prevent recursive subscription because position component is subscribed to positions
            positionData.data.day.forEach((element) => {
                window.wire.subscribe(element.exchange, element.token, "full")
            })
        }

        return positionData;
    }

    async holdings() {
        // method used for getting holdings
        let holdingData = await this.fetchCalls(this.holdingUrl, "GET");
        console.log("holding data", holdingData);
        console.log("holding state from Redux store:", appStore.getState().holdings);
        appStore.dispatch(setHoldingsAction({ holdings: holdingData.data }));
        return holdingData;
    }

    async margin(payload) {
        // method used for getting margin for single order
        let marginData = await this.fetchCalls(this.marginUrl, "POST", payload);
        console.log("single order margin data", marginData);
        return marginData;
    }

    async margins(payload) {
        // method used for getting margin for basket order
        let marginData = await this.fetchCalls(this.marginsUrl, "POST", payload);
        console.log("basket order margin data", marginData);
        return marginData;
    }

    async quote(exchange, token, mode) {
        // method used for getting quote

        // client.authToken=this.authToken
        // let ltp = await client.quotes([token],Constants.Constants.QuoteModes.FULL)
        // console.log("LTP",ltp)

        let response = await this.fetchCalls(`https://vortex.trade.rupeezy.in/data/quotes?q=${exchange}-${token}&mode=${mode}`, "GET")
        // let response = await this.fetchCalls(`https://vortex.restapi.asthatrade.com/data/quotes?q=${exchange}-${token}&mode=${mode}`, "GET")
        // $("textarea").val(JSON.stringify(response))
        console.log("quote response", response);
        return response["data"];

    }

    async getLtp(exchange, token) {
        const data = await this.quote(exchange, token, "ltp")
        const ltp = data[exchange + "-" + token]["last_trade_price"]
        console.log("ltp", ltp)
        return ltp;


    }

    async historicalData(exchange, token, to, from, resolution) {
        // method used for getting historical data
        let url = this.historicalUrl
            .replace("{exchange}", exchange)
            .replace("{token}", token)
            .replace("{to}", to)
            .replace("{from}", from)
            .replace("{resolution}", resolution);
        console.log("historical data url", url);
        let historicalData = await this.fetchCalls(url, "GET");
        console.log("historical data", historicalData);
        return historicalData;
    }

    async symbolSearch(searchText) {
        // method used for searching symbol
        let symbolData = await fetch(
            `https://wave2.restapi.asthatrade.com/nontransactional/101/v1/getScripLookUp/${searchText}/2067`,
            {
                headers: {
                    authorization: `Bearer ${this.authToken}`,
                    "x-api-key": "pPB3vi7IjK1thsTmvKhx3oiVlP1aUt06zi8gFKma",
                },
            }
        );
        symbolData = await symbolData.json();
        console.log("symbol search ", symbolData);
        return symbolData.result.hits.hits;
    }

    async getOptionChain(
        symbol,
        expiryDate = 0,
        instrumentType = "nse",
        addgreek = true
    ) {
        // method used for getting option chain
        const webAccessToken = await helper.getData("webAccessToken")
        console.log("web access token from local storage", webAccessToken)
        let data = await fetch(
            "https://flow-backend.rupeezy.in/api/v2/optionchain",
            {
                headers: {
                    accept: "application/json, text/plain, */*",
                    "accept-language": "en-US,en;q=0.9",
                    authorization: "Bearer " + webAccessToken,
                    "cache-control": "no-cache",
                    "content-type": "application/json",
                    pragma: "no-cache",
                    "sec-ch-ua":
                        '"Not.A/Brand";v="8", "Chromium";v="114", "Google Chrome";v="114"',
                    "sec-ch-ua-mobile": "?0",
                    "sec-ch-ua-platform": '"Windows"',
                    "sec-fetch-dest": "empty",
                    "sec-fetch-mode": "cors",
                    "sec-fetch-site": "same-site",
                    Referer: "https://flow.asthatrade.com/",
                    "Referrer-Policy": "strict-origin-when-cross-origin",
                },
                body: JSON.stringify({
                    symbol: symbol,
                    instrumentType: instrumentType,
                    expiryDate: parseInt(expiryDate),
                    addgreek: addgreek,
                }),
                method: "POST",
            }
        );
        // console.log("datta", data)
        data = await data.json();
        console.log("option chain data", data, data.response);
        if (data?.message === "provide a valid Authorization token") {
            message.error("Session Expired Please Login Again")
            // should redirect to login page

        }
        appStore.dispatch(setOptionChainsAction({ optionChain: data.response }));
        return data?.response;
    }

    async getSymbolInfo(exchange, symbol) {
        let info = await fetch(
            `https://flow-backend.rupeezy.in/api/v2/symbol?symbol=${exchange}:${symbol}`,
            {
                headers: {
                    authorization: `${this.webAccessToken}`,
                    "x-stage": "production",
                },
            }
        );
        info = await info.json();
        return info;
    }
    async tokenToSymbol(exchangeId, token) {
        let response = await fetch(
            `https://flow-backend.rupeezy.in/api/v2/tokenToSymbol/${exchangeId}/${token}`,
            {
                headers: {
                    authorization: `${this.webAccessToken}`,
                    "x-application-id": "at_EJ02vWKDwS",
                    "x-stage": "production",
                },
            }
        );
        response = await response.json();
        return response;
    }

    async pnl() {
        // method used for getting pnl refer to documentation for more details
        let positionData = await this.positions();
        let rpnl = 0;
        let urpnl = 0;
        positionData = positionData?.data?.day;
        console.log("day array", positionData);
        const self = this;
        $.each(positionData, async function (index, value) {
            console.log("value", value);
            let quantity = value?.quantity;
            // let ltp = await self.quote(value?.exchange, value?.token, "ltp")
            let ltp = parseFloat($(`#ltp-${value?.token}`).html());
            console.log("ltp", ltp);
            // ltp = ltp?.data?.[0]?.ltp
            if (quantity === 0) {
                // position is squared off
                console.log("position is squared off", value);
                rpnl += parseFloat(value["value"].toFixed(2));
            } else {
                // when position is open
                if (quantity > 0) {
                    console.log("Long Position", value);
                    let current_urpnl =
                        ltp * value?.quantity * value?.lot_size * value?.multiplier +
                        value?.overnight_sell_value -
                        value?.buy_price -
                        value?.overnight_buy_value;
                    console.log("current urpnl long", current_urpnl);
                    urpnl += current_urpnl;
                    // for long position
                } else {
                    // for short position
                    console.log("Short Position", value);
                    // let current_urpnl = (value?.sell_price - ltp) * (value?.quantity * -1)
                    let current_urpnl =
                        value?.overnight_sell_value -
                        (value?.overnight_buy_value +
                            ltp * value?.quantity * value?.lot_size * value?.multiplier);
                    console.log("current urpnl", current_urpnl);
                    urpnl += current_urpnl;
                }
            }
            console.log("total rpnl & urpnl", rpnl, urpnl);
        });

        console.log("total pnl", rpnl);
        appStore.dispatch(setPnlAction({ rpnl: rpnl, urpnl: urpnl }));
        $("#rpnl").html(rpnl.toFixed(2));
        $("#urpnl").html(urpnl.toFixed(2));
    }

    async instrument() {
        // method used to download csv of instruments
        let instrumentData = await this.fetchCalls(
            this.instrumentUrl,
            "GET",
            {},
            true,
            "*/*",
            "application/json",
            false
        );
        console.log("instrument data", instrumentData);
        // store historical data
    }

    async ResponseFormatter(responseData) {
        // method used to parse instrument endpoint response
        // Split the CSV data into lines
        const lines = responseData.split("\n");

        // Extract the headers from the first line
        const headers = lines[0].split(",");

        // Extract the data from the remaining lines
        const data = lines.slice(1).map((line) => {
            const values = line.split(",");
            const obj = {};
            headers.forEach((header, index) => {
                obj[header] = values[index];
            });
            return obj;
        });

        // Print the data
        return data;
    }


    async getToken(auth_token = "") {
        // method used for getting token from chrome storage
        let data;
        if (auth_token === "") {
            console.log("auth token set from storage");
            data = await chrome?.storage.sync.get("access_token") || "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU5NjI4MTYsInN1YiI6IkxlYXJuaW5nUG93ZXIiLCJwYXlsb2FkIjp7ImlkIjoiNjU4N2E1Nzg1ODI4NGI2ZjBkMjU4MjE2IiwicmV2aXNpb25faWQiOm51bGwsInVzZXJuYW1lIjoiTGVhcm5pbmdQb3dlciIsInBhc3N3b3JkIjoiTGVhcm5pbmdQb3dlciIsImlzX2FjdGl2ZSI6dHJ1ZSwiaXNfdmVyaWZpZWQiOnRydWUsImNyZWF0ZWRfYXQiOiIyMDIzLTA4LTIxVDIwOjIwOjI4LjQ4NiIsInVwZGF0ZWRfYXQiOiIyMDIzLTA4LTIxVDIwOjIwOjI4LjQ4NiIsInNjb3BlIjpbImluZGljYXRvcl9jbGllbnQiXSwiY29va2llcyI6IiJ9fQ.HRc4hv0EgmeyW7GA84fkc-9pCtJomK6or8Px3S7Ni_c";
            data = data.access_token;
        } else {
            console.log("auth token from function update");
            data = auth_token;
        }

        this.authToken = "Bearer " + data;
        console.log("auth token", this.authToken);
        return this.authToken;

        function onPriceUpdate(updates) {
            helper.webSocketMessageHandler(updates);
        }

        async function onOrderUpdate(update) {
            console.log("onOrderUpdate from Astha ", update);
            update = JSON.parse(update);

            Message("", update.data.status_message);
            await helper.showPositions();
            await helper.updateOrderBook();
        }

        function onClose(reason) {
            console.log("onClose from Astha ", reason);
        }

        function onError(reason) {
            console.log("onError from Astha ", reason);
        }
        wire.connect();
        window.wire = wire;
        console.log("wire global again", wire);
    }

    async webSocket() {
        // method used for subscribing to web socket

        // setInterval(() => { helper.webSocketMessageHandler([{ "token": 52341, "last_trade_price": 1222.23 }]) }, 3000)
        let auth_token = this.authToken.replace("Bearer ", "");
        console.log("auth token", auth_token);
        const wire = new VortexFeed.VortexFeed(auth_token, true, true);
        window.wire = wire
        wire.on("price_update", this.onPriceUpdate);
        wire.on("order_update", this.onOrderUpdate);
        wire.on("error", this.onError);
        wire.on("close", this.onClose);
        wire.on("connect", onConnect);

        console.log("wire global", wire);

        function onConnect() {
            helper.WsSubscribe(
                Constants.Constants.ExchangeTypes.NSE_EQUITY,
                26000,
                Constants.Constants.QuoteModes.FULL
            );
            helper.WsSubscribe(
                Constants.Constants.ExchangeTypes.NSE_EQUITY,
                26009,
                Constants.Constants.QuoteModes.OHLCV
            );
        }
    }

    async closeAllPositions() {
        const openPositions = await this.positions()
        console.log("open positions", openPositions)
        for (const element of openPositions?.data?.day) {
            if (element.quantity === 0) {
                console.log("Quantity is Zero Cannot Place Order")
                continue

            }
            const nextPositionType = element.quantity > 0 ? "SELL" : "BUY";

            const orderData = {
                "exchange": element.exchange,
                "token": element.token,
                "transaction_type": nextPositionType,
                "product": "INTRADAY",
                "variety": "RL-MKT",
                "quantity": Math.abs(element.quantity),
                "price": 0,
                "trigger_price": 0,
                "disclosed_quantity": 0,
                "validity": "DAY",
                "validity_days": 1,
                "is_amo": false,
            }
            console.log("current postion", element)
            console.log("order data", orderData)
            await this.placeOrder(orderData)
        }

    }
}

// start = async () => {

//     let api = new AsthaApi(
//         "eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODkyMTU0MDAsImp0aSI6ImF1dGgiLCJpYXQiOjE2ODkxNDk1MDQsImlzcyI6IkFzdGhhVHJhZGUiLCJzdWIiOiJBSDA0MDUiLCJkZXZpY2VJZCI6IjE2ODgxMjA5NDEwMTYiLCJhcHBsaWNhdGlvbklkIjoiZGV2X2JUaTVDUUIxIiwiaXAiOiIyMC4yMTkuMTcwLjY2Iiwic2NvcGUiOiJwcm9maWxlLnJlYWQsb3JkZXJzLnJlYWQsb3JkZXJzLndyaXRlLGhvbGRpbmdzLnJlYWQscG9zaXRpb25zLnJlYWQscG9zaXRpb25zLndyaXRlLGZ1bmRzLnJlYWQsdHJhZGVzLnJlYWQsbWFyZ2lucy5yZWFkLGRhdGEucmVhZCJ9.s3PeH8gTwrlM6PhZw-t-wEu9Bq7Dkmiw33PWdnVVJ18PyzI8iayINONt1CgmY3soM8_spNK_2cqUsZ6GKFTNtHw2Xam5oq8tLg1kv0-zU4bkUa0AUT6TAJOuYapCZ_TUtkvYfAK4gk2XT_5MVCpbhZ7uFeHYyoWPrgGsRRaJCP0UjVSjZ1PnAI4Wq0UtDO4r8HYSZG079DOYvkjpQl__Nej8WwuYIxTmjQ8r9lIxjfO-F3PvfGKyvUx4oOfdVjn6kWR_gABENs_IfoQSaQ9YUYCLYehI0Oi3jjWkboCuhQM-fFJwKXxK8CCA8F_zCXXrwQqLeWTTBkhzGb_jHw7N754J0WmipOz4ofv64OhhpG5z7GwQdwH-WbNX0gkpcyKq07gIvxPbPRhQBMWgWOyELxy8IPIHDNJVLby86InNCAbFpS7yWBxXDJb9I6bxAWcAV0yLLBMYB2d9tmt0udbsVRt0bnmhFtaNLruiaRls3ZqXmH2EZ_X8kCVobilr1-Su"
//     )

//     let data = await api.getFunds();
//     console.log(data);
//     data = await api.holdings();
//     console.log(data)

//     data = await api.getOrders(3,1)
//     console.log("order data", data)

//     data = await api.positions();
//     console.log("postion response", data);
//     // Insert("Position " + data);

//     console.log("Holding" + data);
//     // Insert("Holding " + data);

//     data = await api.margin({
//         "token": 22,
//         "exchange": "NSE_EQ",
//         "transaction_type": "BUY",
//         "product": "INTRADAY",
//         "variety": "RL",
//         "quantity": 50,
//         "price": 80,
//         "mode": "MODIFY",
//         "old_quantity": 50,
//         "old_price": 50
//     }

//     );
//     console.log("margin" + data);
//     // Insert("Margin " + data);

//     data = await api.margins({
//         "orders": [
//             {
//                 "token": 22,
//                 "exchange": "NSE_EQ",
//                 "transaction_type": "BUY",
//                 "product_type": "INTRADAY",
//                 "variety": "RL",
//                 "quantity": 1,
//                 "price": 1122.35
//             }
//         ]
//     }

//     );
//     console.log("margins" + data);
//     // // Insert("Margins " + data);

//     data = await api.quote("NSE_FO", "37504", "full");
//     console.log("quote response" + data);
//     // Insert("quote response" + data);

//     data = await
//         api.historicalData("NSE_EQ", "22", "1686725474", "1686120674", "1")
//         ;
//     console.log("historical", data);
//     // Insert("Historical " + data);

//     // await api.webSocket();

// }
// start()

// // export default AsthaApi
