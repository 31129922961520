
const stocksArray = [
  { id: 1, name: "Nifty" },
  { id: 2, name: "BankNifty" },
  { id: 3, name: "SBIN" },
  { id: 4, name: "HDFCBANK" },
  { id: 5, name: "ICICIBANK" },
  { id: 6, name: "KOTAKBANK" },
  { id: 7, name: "RELIANCE" },
  { id: 8, name: "TCS" },
  { id: 9, name: "INFY" },
  { id: 10, name: "HINDUNILVR" },
  { id: 11, name: "ITC" },
  { id: 12, name: "FINNIFTY" },
  { id: 13, name: "LT" },
  { id: 14, name: "MARUTI" },
  { id: 15, name: "BAJAJ-AUTO" },
  { id: 16, name: "NTPC" },
  { id: 17, name: "WIPRO" },
  { id: 18, name: "GAIL" },
  { id: 19, name: "CIPLA" },
  { id: 20, name: "ULTRACEMCO" },
];


var niftyLTP = await window.asthaApi.quote("NSE_EQ", 26000, "full")
console.log("NIFTY LTP", niftyLTP)
niftyLTP = niftyLTP?.["NSE_EQ-26000"]
const niftyChange = niftyLTP?.last_trade_price - niftyLTP?.open_price
var bankNiftyLTP = await window.asthaApi.quote("NSE_EQ", 26009, "full")
bankNiftyLTP = bankNiftyLTP?.[`${"NSE_EQ"}-${"26009"}`]
const bankNiftyChange = bankNiftyLTP?.last_trade_price - bankNiftyLTP?.open_price

export const marketIndices = [
  { name: "Nifty", value: niftyLTP?.last_trade_price, change: niftyChange.toFixed(2), percentageChange: ((niftyChange / niftyLTP?.open_price) * 100).toFixed(2), token: 26000 },
  { name: "BankNifty", value: bankNiftyLTP?.last_trade_price, change: bankNiftyChange.toFixed(2), percentageChange: ((bankNiftyChange / bankNiftyLTP?.open_price) * 100).toFixed(2), token: 26009 },
  { name: "FinNifty", token: 26037 },
  // {
  //   name: "Dow Jones",
  //   value: 35511.85,
  //   change: -54.55,
  //   percentageChange: -0.15,
  // },
  // { name: "S&P 500", value: 4482.75, change: 8.65, percentageChange: 0.19 },
  // { name: "FTSE 100", value: 7258.36, change: 10.12, percentageChange: 0.14 },
  // { name: "NASDAQ", value: 15247.44, change: 25.3, percentageChange: 0.17 },
];

export default stocksArray;
