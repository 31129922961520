import React from "react";
import { RiMenuAddFill } from "react-icons/ri";
import "./Basket.css";

export const Basket = () => {
  return (
    <div className="basket-page">
      <div className="basket-container">
        <div className="basket-card">
          <h4>Basket 1</h4>
          <div>
            <span>1/20</span>
          </div>
        </div>

        <div className="basket-card">
          <h4>Basket 2</h4>
          <div>
            <span>0/20</span>
          </div>
        </div>
        <div className="basket-card">
          <h4>Basket 3</h4>
          <div>
            <span>0/20</span>
          </div>
        </div>
        <div className="basket-card">
          <h4>Basket 4</h4>
          <div>
            <span>0/20</span>
          </div>
        </div>
      </div>

      {/* manage baskets div */}
      <div className="manage-baskets">
        {/* icon */}
        <RiMenuAddFill className="manage-basket-icon" size={20} />
        <p>Manage baskets</p>
      </div>
    </div>
  );
};
