import { combineReducers } from "redux";
import { setProfileReducer } from "../redux/profile/setProfileAction";
import { setPositionsReducer } from "../redux/positions/setPositionsAction";
import { setOrderBooksReducer } from "../redux/orderBook/setOrderBookAction";
import { setFundsReducer } from "../redux/funds/setFundsAction";
import { setOptionChainsReducer } from "../redux/optionChains/setOptionChainAction";
import { setHoldingsReducer } from "./holdings/setHoldingAction";
import { setPnlReducer } from "./pnl/setPnlAction";
import { searchResultsReducer } from "./searchResults/setSearchResultsAction";
import { orderDataReducer } from "./orderData/setOrderDataAction";
import { tslReducer } from "./Tsl/setTslAction";
import { setDragPositionReducer } from "./dragPosition/setDragPosition";
import { setNotificationsReducer } from "./notifications";

export const rootReducer = combineReducers({
  profile: setProfileReducer,
  positions: setPositionsReducer,
  orderBook: setOrderBooksReducer,
  funds: setFundsReducer,
  optionChain: setOptionChainsReducer,
  holdings: setHoldingsReducer,
  pnl: setPnlReducer,
  selectSearchResult: searchResultsReducer,
  orderData: orderDataReducer,
  tsl: tslReducer,
  dragPosition: setDragPositionReducer,
  notifications: setNotificationsReducer,
});
