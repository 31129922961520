import React from "react";
import { GoHome } from "react-icons/go";
import { MdOutlineEventNote } from "react-icons/md";
import { FaLink } from "react-icons/fa";
import { BsGraphUpArrow, BsBag } from "react-icons/bs";
import { IoIosSettings } from "react-icons/io";
import { IoIosNotifications } from "react-icons/io";

import "./NavigationItems.css";
import { Link, useNavigate } from "react-router-dom";

export const NavigationItems = () => {
  const navigate = useNavigate();
  return (
    <div className="navigation-items">
      <div className="item">
        {/* icon */}
        <Link to={"/trade"}>
          <GoHome className="item-icon" size={25} />

          {/* item name */}
          <p className="item-name">Home</p>
        </Link>
      </div>

      <div
        className="item"
        onClick={() => {
          window.asthaApi.getOrders();
          navigate("/orders");
        }}>
        {/* icon */}
        <MdOutlineEventNote className="item-icon" size={22} />

        {/* item name */}
        <p className="item-name">Orders</p>
      </div>

      <Link to={"/portfolio"}>
        <div className="item">
          {/* icon */}
          <BsBag className="item-icon" size={22} />

          {/* item name */}
          <p className="item-name">Positions</p>
        </div>
      </Link>

      <Link to={"/option-chain"}>
        <div className="item">
          {/* icon */}
          <FaLink className="item-icon" size={22} />

          {/* item name */}
          <p className="item-name">Option chain</p>
        </div>
      </Link>

      <Link to={"/tools"}>
        <div className="item">
          {/* icon */}
          <IoIosSettings className="item-icon" size={22} />

          {/* item name */}
          <p className="item-name">tools</p>
        </div>
      </Link>
      <Link to={"/notifications"}>
        <div className="item">
          {/* icon */}
          <IoIosNotifications className="item-icon" size={22} />

          {/* item name */}
          <p className="item-name">Notifications</p>
        </div>
      </Link>
    </div>
  );
};
