import { createAction } from "redux-actions";

const setOrderDataRdxConst = 'SET_ORDERDATA'

export const setOrderDataAction = createAction(setOrderDataRdxConst, (payload) => payload)

export const orderDataReducer = (state = {orderData: {}}, action) => {
 switch (action.type) {
  case setOrderDataRdxConst:
   return { ...state, ...action.payload }
  default: 
   return state
 }
}