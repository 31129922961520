/*global chrome*/
import "./App.css";

import ProfilePage from "./pages/Profile.jsx";
import { Provider } from "react-redux";
import { appStore } from "./redux/store.js";
import React, { useContext, useState, createContext } from "react";
import { Route, MemoryRouter as Router, Routes } from "react-router-dom";
import LoginPage from "./pages/Login.jsx";
import PortfolioPage from "./pages/Portfolio.jsx";
import OrdersPage from "./pages/Orders.jsx";
import OptionChainPage from "./pages/OptionChain.jsx";
import SearchResultsPage from "./pages/SearchResults.jsx";
import Tools from "./pages/Tools.jsx";
import Register from "./pages/Register.jsx";
import Funds from "./pages/Funds.jsx";
import Trade from "./pages/Trade.jsx";
import NotificationsPage from "./pages/Notifications.jsx";
// import changeLogPage from "./pages/changeLog.jsx";

const DragContext = createContext();

export const useDragContext = () => useContext(DragContext);

function App() {
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = ui;

    clearTimeout(handleDrag.timeout);
    handleDrag.timeout = setTimeout(() => {
      setDragPosition({ x, y });
    }, 10);
  };
  return (
    <Provider store={appStore}>
      <DragContext.Provider value={{ dragPosition, handleDrag }}>
        <Router>
          <Routes>
            <Route path="/" element={<Register />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/funds" element={<Funds />} />
            <Route path="/trade" element={<Trade />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/option-chain" element={<OptionChainPage />} />
            <Route path="/search-results" element={<SearchResultsPage />} />
            <Route path="/tools" element={<Tools />} />
            <Route path="/notifications" element={<NotificationsPage />} />
            {/* <Route path="/change-log" element={<changeLogPage />} /> */}
          </Routes>
        </Router>
      </DragContext.Provider>
    </Provider>
  );
}

export default App;
