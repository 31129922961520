// This file is used to create additional function which are generally  required for data communication between api and other helping function 
/* eslint-disable */
// This Function is called whenever the pe button is clicked
import { local } from "d3";
import AsthaApi from "./apiCalls";
import { Message, formData } from "./eventAdder";
import * as opt from "../external_js/opt"
window.hardGreenColor = "rgb(0, 249, 65)"



export async function AsthaIntialize() {
  // function used to check session token for astha and intialize the class
  //step 1check session in storage
}

export async function saveData(obj) {
  if (typeof chrome !== 'undefined' && chrome.storage && chrome.storage.sync) {
    return new Promise((resolve, reject) => {
      chrome.storage.sync.set(obj, () => {
        if (chrome.runtime.lastError) {
          reject(chrome.runtime.lastError);
        } else {
          resolve();
        }
      });
    });
  } else {
    Object.keys(obj).forEach(key => {
      localStorage.setItem(key, JSON.stringify(obj[key]));
    });
    return Promise.resolve();
  }
}
export async function getData(key) {
  if (typeof chrome !== 'undefined' && chrome.storage && chrome.storage.sync) {
    return new Promise((resolve, reject) => {
      chrome.storage.sync.get(key, (result) => {
        if (chrome.runtime.lastError) {
          reject(chrome.runtime.lastError);
        } else {
          resolve(result[key]);
        }
      });
    });
  } else {
    const value = localStorage.getItem(key);
    return Promise.resolve(value ? JSON.parse(value) : null);
  }
}

export async function showFund() {
  // function used to show funds in the dialog box
  let asthaApi = window.asthaApi
  let response = await asthaApi.getFunds()
  console.log(" funds response", response)
  // $("#funds-nse").text("₹" + parseFloat(response?.nse?.net_available).toFixed(2))
  // $("#funds-mcx").text("₹" + parseFloat(response?.mcx?.net_available).toFixed(2))


}

export async function searchHandler() {
  // this function is used to send search value to broker api and search result back and to display
  let to_search = $("#symbol").val();
  let asthaApi = window.asthaApi
  let searchResult = await asthaApi.symbolSearch(to_search)
  console.log("before function call")
  updateSearchList(searchResult, "ul.search-list")
  console.log("after function call")
  return searchResult
}



export function webSocketMessageHandler(message) {
  // message will be an array ,if price true then update the input price tag also
  message = message[0]
  // console.log("web socket message updating ...", message)
  let token = message.token
  let currentPrice = $(`.${token}`)
  if (currentPrice.length === 0) {
    // when no element are found with this class name
    wire.unsubscribe(message.exchange, message.token, "full")
    console.log("No Element found with this class name for token", token)
    WsSubs(token, "set", false)

  }

  currentPrice = currentPrice.html()
  // console.log("current price",price)
  let color = currentPrice > message.last_trade_price ? "red" : "#00f941"
  $(`.${token}`).html(message.last_trade_price)
  $(`.${token}`).css({ "color": color })
  let priceTag = $(`input.${token}`)
  // console.log("price tag", priceTag)
  priceTag.val(message.last_trade_price)
  CheckTSL(token, message.last_trade_price)
  updatePositionPnl(token, message.last_trade_price)
  // window.asthaApi.pnl()

  // console.log("value changed")
  // console.log("new value", priceTag.val())

  // console.log("web socket update complete for token", token)

}

export async function pingServer() {
  fetch("https://tradex.kredallino.tech/")
  console.log("pinged server")
}


export async function getOptionChain(symbolName = false, expiryDate = 0, instrumentType = 'nse', addgreek = true) {
  // function used for option chain display and selection 
  console.log("INSIDE get option chain function")
  if (!symbolName) {
    symbolName = $("input[name=quickSelector]:checked").attr("data-option")
  }
  console.log("search text for optionchain", symbolName)
  let searchResult = await window.asthaApi.getOptionChain(symbolName, expiryDate, instrumentType, addgreek)
  console.log("option chain data", searchResult)
  // let limit = $("#limit").val()
  // let minimum = $("#minStrikePrice").val()
  // let maximum = $("#maxStrikePrice").val()
  // console.log("min max lim", minimum, maximum, limit)
  let select = ".optionChainList"
  updateOptionChain(searchResult)
}


export async function updateSearchList(searchResult, selector = "ul.search-list", limit = 10, minimum = 0, maximum = 999999, header = "#symbol-holder") {
  // function used to update the search result list 
  console.log("inside  update search list function call", searchResult.length)
  $(`${header}`).css({ "display": "block" })
  $(`${selector}`).css({ "display": "block" })
  $(`${selector}`).empty() // remove the previous list items
  // Check if searchResult array is empty
  if (searchResult.length === 0) {
    $(`${selector}`).append(`<li>No result found</li>`);
    return;
  }
  // limit output to top 10 only
  for (let i = 0; i < searchResult.length && i < limit; i++) {
    let data = searchResult[i]
    let token = data._source.nToken
    let currentStrikePrice = data._source.nStrikePrice1
    let exchange = data._source.ExchangeSegment
    let quanity = data._source.nRegularLot
    let optionChain = data._source.nFOExists
    let complete_name = `${data._source.sSymbol} ${data._source.nStrikePrice1} ${data._source.nExpiryDate1} ${data._source.sOptionType}`

    // to filter data



    // $(`${selector}`).append(`<option data-quantity=${quanity} data-exchange=${exchange} data-token=${token} value="${complete_name}">`)

    $(`${selector}`).append(`<li class='trade-x search-item'>
      <a href="#" class="trade-x search-link">
          <span class="${token} trade-x search-token"  data-quantity=${quanity} data-exchange=${exchange} data-token=${token} data-optionChain=${optionChain} data-symbol=${data._source.sSymbol}>${complete_name} </span>
      </a>
  </li>`)




  }
}


export async function selectSearchList(selector = ".optionChainList") {
  console.log("on click select event added")
  // function used to select the item from the search list
  $(`${selector}`).on("click", "li", async function () {
    console.log("On select event triggered")
    // Retrieve the clicked element
    const clickedElement = $(this);

    // Access any data or perform actions on the clicked element
    const token = clickedElement.find("span").data("token");
    let exchange = clickedElement.find("span").data("exchange")
    let quantity = clickedElement.find("span").data("quantity")
    let optionChain = clickedElement.find("span").data("optionchain")
    let optionSymbol = clickedElement.find("span").data("symbol")
    const text = clickedElement.find("span").text();
    // fetch ltp
    WsSubscribe(exchange, token, "full")
    // Log the clicked element and its data
    InsertSymbolInfo(token, exchange, text, quantity, optionChain, optionSymbol)
    $("#symbol-holder").hide()

  });
}


export async function InsertSymbolInfo(token, exchange, symbolName, quantity = "25", optionChain = 0, optionSymbol = "BANKNIFTY") {
  // function used to update the  symbol info in the form
  console.log("Inside insert symbol info function data", token, exchange, symbolName, quantity, optionChain, optionSymbol)
  WsSubscribe(exchange, token, "full")
  document.querySelector(`input[name=exchange][value='${exchange}']`).checked = true
  $("#price")[0].classList = ""
  $("#price").addClass(`input-text`)
  $("#price").addClass(`${token}`)
  $("#symbol").val(symbolName)
  console.log("class list of ltp", $("#ltp")[0].classList)
  $("#ltp")[0].classList = ""
  $("#ltp").addClass(`${token}`)
  let ltp = await window.asthaApi.quote(exchange, token, "ltp")
  ltp = ltp[`${exchange}-${token}`].last_trade_price
  console.log("class list of ltp", $("#ltp")[0].classList)
  $("#ltp").html(ltp)
  document.querySelector("#quantity").value = 1
  $("#lotSize").html(quantity)
  document.querySelector("#token").value = token
  let color = optionChain === 1 ? "#0dff00" : "#f10606";
  $("#option-chain-svg").css({ "color": color })
  $("#small-option-chain").attr("data-optionSymbol", optionSymbol)
  WsSubscribe(exchange, token, "full")
  pingServer()
}


export function getExpiryDate(dateNumber, split = "-") {
  // function used to convert astha datetime for expiry date
  let dateString = dateNumber.toString();
  let year = dateString.slice(0, 4);
  let month = dateString.slice(4, 6);
  let day = dateString.slice(6, 8);

  let dateObject = new Date(`${year}-${month}-${day}`);
  let currentExpiry = dateObject.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  if (split === "") {
    return currentExpiry.replaceAll(" ", "")
  }
  return currentExpiry;

}


// not being used 
export async function optionFilter(optionData, optionType = "PE", filterParam, filterOperator, filterValue) {

  //function to filter option data  
  console.log("function input", optionType, filterParam, filterOperator, filterValue)
  for (const item of optionData) {
    console.log("Item", item)
    let value = item[optionType]['greeks'][filterParam]
    let expression = `${value.toFixed(2)} ${filterOperator} ${filterValue.toFixed(2)}`
    console.log("expression", expression)
    let result = opt.evaluateExpression(expression)
    if (result) {
      console.log("true ", item[optionType]['securityDesc'])
      let expiry_date = getExpiryDate(item[optionType]['expYYYYMMDD'])
      let complete_name = `${item[optionType]['symbol']} ${item[optionType]['strikePrice'] / 100} ${expiry_date} ${item[optionType]['optionType']}`
      console.log("complete name", complete_name)
      InsertSymbolInfo(item[optionType]['token'], "NSE_FO", complete_name, item[optionType]['lotSize'], 0, item[optionType]['symbol'])
      return true
    }
    console.log("result", result, item[optionType]['greeks'][filterParam])
    return false
  }
}



export async function insertHtml() {
  // function used to insert the test_file.html code in the webpage
  // let filePath = "./chrome_extension-astha_version_0.0.1/demo.html"
  let filePath = "./html/dialog.html"
  let file_URL = await chrome.runtime.getURL(filePath);

  console.log("File url", file_URL)
  let button_and_form_code
  await $.ajax({
    url: file_URL,
    dataType: 'html',
    success: function (text) {
      button_and_form_code = $(text);
      console.log("button and form code from insert function:", button_and_form_code);
    },
    error: function (xhr, status, error) {
      console.log("error response during fetching the html code from insert function", error, xhr, status);
    }
  });

  $("body").append(button_and_form_code);
  console.log("HTML code append Successfull function")
}



export async function createTableFromJson(jsonArray, headers = ["exchange", "symbol", "quantity", "average_price", "value"], attributes = ["exchange", "token", "quantity", "product"]) {

  // Extract the keys from the first object in the JSON array
  let main_table = $("#position-table tbody")
  main_table.empty()

  // jsonArray =
  //   [
  //     { "exchange": "NSE_FO", "symbol": "CRUDEOIL", "expiry_date": "2023-08-17", "option_type": "CE", "token": 52341, "product": "INTRADAY", "quantity": 15, "overnight_buy_value": 0, "overnight_sell_value": 0, "overnight_average_price": 0, "lot_size": 100, "multiplier": 100, "average_price": 17, "value": -1700, "buy_value": 3430, "sell_value": 1730, "buy_quantity": 30, "sell_quantity": 15, "buy_price": 17.15, "sell_price": 17.3 },

  //   ]

  // {
  //   "exchange": "NSE_FO",
  //   "symbol": "BANKNIFTY",
  //   "expiry_date": "2023-08-03",
  //   "option_type": "CE",
  //   "token": 43467,
  //   "product": "INTRADAY",
  //   "quantity": 2,
  //   "overnight_buy_value": 0,
  //   "overnight_sell_value": 0,
  //   "overnight_average_price": 0,
  //   "lot_size": 15,
  //   "multiplier": 1,
  //   "average_price": 0,
  //   "value": -53.25,
  //   "buy_value": 2545.5,
  //   "sell_value": 2492.25,
  //   "buy_quantity": 15,
  //   "sell_quantity": 15,
  //   "buy_price": 169.7,
  //   "sell_price": 166.15
  // },
  // {
  //   "exchange": "NSE_FO",
  //   "symbol": "BANKNIFTY",
  //   "expiry_date": "2023-08-03",
  //   "option_type": "PE",
  //   "token": 43471,
  //   "product": "INTRADAY",
  //   "quantity": 3,
  //   "overnight_buy_value": 0,
  //   "overnight_sell_value": 0,
  //   "overnight_average_price": 0,
  //   "lot_size": 15,
  //   "multiplier": 1,
  //   "average_price": 0,
  //   "value": 595.5,
  //   "buy_value": 4492.5,
  //   "sell_value": 5088,
  //   "buy_quantity": 30,
  //   "sell_quantity": 30,
  //   "buy_price": 149.75,
  //   "sell_price": 169.6
  // },
  // {
  //   "exchange": "NSE_FO",
  //   "symbol": "BANKNIFTY",
  //   "expiry_date": "2023-08-03",
  //   "option_type": "PE",
  //   "token": 43468,
  //   "product": "INTRADAY",
  //   "quantity": 4,
  //   "overnight_buy_value": 0,
  //   "overnight_sell_value": 0,
  //   "overnight_average_price": 0,
  //   "lot_size": 15,
  //   "multiplier": 1,
  //   "average_price": 0,
  //   "value": -69.75,
  //   "buy_value": 5700,
  //   "sell_value": 5630.25,
  //   "buy_quantity": 30,
  //   "sell_quantity": 30,
  //   "buy_price": 190,
  //   "sell_price": 187.68
  // },
  // {
  //   "exchange": "NSE_FO",
  //   "symbol": "BANKNIFTY",
  //   "expiry_date": "2023-08-03",
  //   "option_type": "CE",
  //   "token": 43470,
  //   "product": "INTRADAY",
  //   "quantity": 1,
  //   "overnight_buy_value": 0,
  //   "overnight_sell_value": 0,
  //   "overnight_average_price": 0,
  //   "lot_size": 15,
  //   "multiplier": 1,
  //   "average_price": 0,
  //   "value": -69,
  //   "buy_value": 1995,
  //   "sell_value": 1926,
  //   "buy_quantity": 15,
  //   "sell_quantity": 15,
  //   "buy_price": 133,
  //   "sell_price": 128.4
  // }

  try {
    var keys = Object.keys(jsonArray[0]);
  } catch (error) {
    console.log("error in creating table from json", error)
    let head = $("<div class='trade-x'>No Data Found</div>")
    main_table.append("<tr> <td> NO OPEN POSITION </td> </tr>")

  }



  // Create table body rows
  var rpnl = 0;
  $("#urpnl").html(0)
  for (const item of jsonArray) {
    let json_data = item
    localStorage.setItem(`position-${item.token}`, JSON.stringify(json_data))
    let quantity = json_data["quantity"]
    let ltp = await window.asthaApi.quote(item.exchange, item.token, "ltp")
    ltp = ltp[`${item.exchange}-${item.token}`]
    ltp = ltp.last_trade_price
    console.log("LTP for open position", ltp)
    var pnl = 0
    if (quantity !== 0) {
      let lot_size = item.lot_size
      let netQty = 1
      if (item.exchange === "NSE_FO") {
        netQty = item.quantity
      }
      else if (item.exchange === "MCX_FO") {
        netQty = item.quantity * item.multiplier
      }
      else {
        netQty = item.quantity * item.lot_size
      }
      console.log("netQty from open position table", netQty)
      // when positin is open
      if (quantity > 0) {
        // when buy position is open
        let cpnl = (ltp - item.buy_price) * (netQty)

        console.log("pnl for single long position in position  book", cpnl)
        pnl += cpnl
        item['bprice'] = item['buy_price']
        item['ptype'] = "Long"
        $("#urpnl").html(parseFloat($("#urpnl").html()) + parseFloat(cpnl.toFixed(2)))


      }
      else {
        // when sell position is open
        let cpnl = (ltp - item.buy_price) * (quantity)
        console.log("pnl for single short position in position book", cpnl)
        pnl += cpnl
        item['bprice'] = item['sell_price']
        item['ptype'] = "Short"
        $("#urpnl").html(parseFloat($("#urpnl").html()) + parseFloat(cpnl.toFixed(2)))

      }


      console.log("string json", json_data)
      console.log("loop1", item)
      console.log("wire subscribe done to open position", item["exchange"], item["token"], "full")

      let symbol = `${item["symbol"]} ${item["expiry_date"]} ${item?.strike_price || ""} ${item["option_type"] || ""}`
      main_table.append(`
    <tr class="position-${item['token']}" data-json=${json_data}>

    <td style='display:none'><div class="lotSize" id="lotSize-${item["token"]}">${item['lot_size']}</div></td>

    <td><div class="symbol">${symbol}</div></td>

    <td><div class="ptype" id='ptype-${item['token']}'>${item["ptype"]}</div></td>

    <td><div class="${item['token']}" id="ltp-${item['token']}">${ltp}</div> </td>

    <td><div id="buyPrice-${item['token']}" >  ${item['bprice']} </div></td>
    
    <td><input class="input-text" type="number" style="width: 50px;"   id="quantity-${item['token']}" value=${item["quantity"]} placeholder=${item['quantity']}> </td>

    <td><div class='position-pnl'  id="pnl-${item['token']}"> ${pnl.toFixed(2)} </div></td>

    <td><input class="input-text target" type="number" data-token=${item['token']} id="target-${item['token']}" value=${window.TSL[item['token']]?.target || -1}  style="width: 50px;"></td>

    <td><input class="input-text stoploss" type="number" data-token=${item['token']} id="stoploss-${item['token']}" value=${window.TSL[item['token']]?.stoploss || -1} style="width: 50px;"></td>

    <td><input class="input-text trailPoint" type="number" data-token=${item['token']} id="trailPoint-${item['token']}" value=${window.TSL[item['token']]?.trailPoint || 0} data-dummyBuyPrice='${window.TSL[item['token']]?.dummyBuyPrice || 0}' style="width: 50px;"></td>
    
    <td><input class="input-text trailPointSl" type="number" data-token=${item['token']} id="trailPointSl-${item['token']}" value=${window.TSL[item['token']]?.trailPointSl || 0} style="width: 50px;"></td>

    <td><button  class="square-off-all-button-css square-off-button button" id="square-off-button-${item['token']}"  data-token=${item['token']}  data-json='${JSON.stringify(item)}' data-target="#position-info-${item['token']}">Square Off</button></td>

    <td><input class="input-text" type="checkbox" name="tsl" id="set-tsl-${item['token']}"   style="width: 25px;display:block"></td>

      </tr>
  `)
      await updatePositionPnl(item['token'], ltp)

      if (window.TSL[item['token']]?.checkTsl) {
        console.log("previous tsl was checked")
        $(`#set-tsl-${item['token']}`).prop("checked", true)
      }
      else {
        // ltp udpate will force to check stoploss trail

        WsSubscribe(item["exchange"], item["token"], "full")
      }



    }
    else {
      // when position is closed
      rpnl += item['value']
    }


  }
  ;

  // let oldRpnl = $("#rpnl").html() // this is 0 by default from dialog.html
  // oldRpnl+=rpnl
  // let color = oldRpnl > 0 ? window.hardGreenColor : "red"
  let color = rpnl > 0 ? window.hardGreenColor : "red"
  $("#rpnl").html(rpnl.toFixed(2))
  console.log("udpated rpnl from open position data")


}

export async function updateOptionChain(searchResult, selector = ".option-chain table tbody") {
  // function used to update the option chain list
  console.log("updating option chain")
  $(`${selector}`).empty() // remove the previous table body items

  let converter = (number) => {
    return parseFloat(parseFloat(number).toExponential(3).substring(0, 4))
  }


  $("#option-name").html(searchResult?.symbol)
  let expiryDateGroup = $("#expiry-date-group")
  expiryDateGroup.empty()

  // to update the current expiry date
  let dateString = searchResult?.curExpDate.toString();
  let year = dateString.slice(0, 4);
  let month = dateString.slice(4, 6);
  let day = dateString.slice(6, 8);

  let dateObject = new Date(`${year}-${month}-${day}`);
  let currentExpiry = dateObject.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  $("#current-expiry").html(currentExpiry)


  searchResult?.expDates?.forEach((item, index) => {


    const dateString = item.toString();
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);

    const dateObject = new Date(`${year}-${month}-${day}`);
    const formattedDate = dateObject.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    expiryDateGroup.append(`<option value="${item}">${formattedDate}</option>`)
  })

  const indexLtp = searchResult.parentStockData.livePrice
  const originalNumber = indexLtp / 100;
  const spotPrice = Math.round(originalNumber / 100) * 100;
  let optionData = searchResult?.optionData
  for (let item of optionData) {

    let lowerBound = spotPrice - 2000
    let upperBound = spotPrice + 2000
    let currentStrike = item?.strikePrice / 100
    if (!(currentStrike >= lowerBound && currentStrike <= upperBound)) {
      console.log("spot price not in range", lowerBound, item.strikePrice, upperBound, spotPrice)
      continue;
    }

    console.log("Option chain Item", item)
    let peWidth = item?.PE?.volume.toString().length
    peWidth = item?.PE?.volume / ("1" + repeatString("0", peWidth - 2))

    let ceWidth = item?.CE?.volume.toString().length
    ceWidth = item?.CE?.volume / ("1" + repeatString("0", ceWidth - 2))
    console.log("ce width", ceWidth)
    let optionNameCall = item?.CE.symbol + " " + (item?.CE.strikePrice / 100) + " " + getExpiryDate(item?.CE.expYYYYMMDD, "") + " CE"
    let optionNamePut = item?.PE.symbol + " " + (item?.PE.strikePrice / 100) + " " + getExpiryDate(item?.PE.expYYYYMMDD, "") + " PE"

    let newCeWidth = normalizeToRange(item?.CE.volume, 0, 1040532760, 0, 50)

    let newPeWidth = normalizeToRange(item?.PE.volume, 0, 1040532760, 0, 50)

    // let ltpPE =await window.asthaApi.quote("NSE_FO", item?.PE?.token,"ltp")
    // ltpPE = ltpPE[`${"NSE_FO"}-${item?.PE?.token}`]?.last_trade_price  || item?.PE?.ltp
    let ltpPE = (item?.PE?.ltp / 100).toFixed(2)
    let ltpCE = (item?.CE?.ltp / 100).toFixed(2)

    // let ltpCE = await  window.asthaApi.quote("NSE_FO", item?.CE?.token,"ltp")
    // ltpCE = ltpCE[`${"NSE_FO"}-${item?.CE?.token}`]?.last_trade_price || item?.CE?.ltp

    console.log("newCeWidth", newCeWidth, "newPewidth", newPeWidth)
    let color = spotPrice == item?.strikePrice / 100 ? "#ffeb3b" : ''
    console.log("color", color)
    let t1data = `
    <tr  data-callOption-name='${optionNameCall}' data-putOption-name='${optionNamePut}' style='background:${color}'> 

    <td class="oi call callOption" >${item?.CE?.openInterest}</td>
    <td class="ltp call callOption  ${item?.CE?.token}" id="ltp-${item?.CE?.token}" > ${ltpCE} </td>
    <td class="volume callOption  call" > <div style="background:#ff4b4b;width:${newCeWidth || 0}px;" > ${item?.CE?.volume} </div> </td>

    <td class="strike">${item?.strikePrice / 100}</td>
    
    <td class="volume put putOption" > <div style="background:#55f155;width:${newPeWidth || 0}px" >${item?.PE?.volume} </div> </td>
    <td class="ltp put putOption  ${item?.PE?.token}"  id="ltp-${item?.PE?.token}" > ${ltpPE}</td>
    <td class="oi put putOption " >${item?.PE?.openInterest}</td>
  </tr>`



    $("#table1 tbody").append(t1data)
    if (spotPrice == item?.strikePrice / 100) {
      $("#table1 tbody").append(`<div id="spotPrice"> </div>`)
    }

    let t2data = `
  <tr  data-callOption-name='${optionNameCall}' data-putOption-name='${optionNamePut}'>
    <td class="delta call callOption" >${item?.CE.greeks.delta.toFixed(2)}</td>
    <td class="strike">${item?.strikePrice / 100}</td>
    <td class="iv call callOption" >${item?.greekIv.toFixed(2)}</td>
    <td class="delta put putOption" >${item?.PE.greeks.delta.toFixed(2)}</td>
    <td class="theta call-put" >${converter(item?.CE.greeks.theta.toFixed(2))}</td>
    <td class="vega call-put" >${converter(item?.CE.greeks.vega.toFixed(2))}</td>
    <td class="gamma call-put" >${converter(item?.CE.greeks.gamma.toFixed(2))}</td>
   </tr>`

    $("#table2 tbody").append(t2data)
    // $(`${selector}`).append(listItem)
    WsSubscribe("NSE_FO", item?.PE?.token, "ltp")
    WsSubscribe("NSE_FO", item?.CE?.token, "ltp")




  }

  $(".callOption").on("click", async (e, optionType = 'callOption') => {
    let symbol = $(e.target).parent("tr").attr(`data-${optionType}-name`)
    console.log("chain select symbol", symbol)
    let searchResult = await asthaApi.symbolSearch(symbol)
    searchResult = searchResult[0]._source
    InsertSymbolInfo(searchResult?.nToken, searchResult?.ExchangeSegment, symbol, searchResult?.nRegularLot, searchResult?.nFOExists, searchResult?.nStrikePrice, searchResult?.sSymbol)

  })

  $(".putOption").on("click", async (e, optionType = 'putOption') => {
    let symbol = $(e.target).parent("tr").attr(`data-${optionType}-name`)
    console.log("chain select symbol", symbol)
    let searchResult = await asthaApi.symbolSearch(symbol)
    searchResult = searchResult[0]._source
    InsertSymbolInfo(searchResult?.nToken, searchResult?.ExchangeSegment, symbol, searchResult?.nRegularLot, searchResult?.nFOExists, searchResult?.nStrikePrice, searchResult?.sSymbol)

  })

  document.getElementById("spotPrice").scrollIntoView({ behavior: 'smooth', block: 'center' });


}

export function Width(value, count = 2) {
  // function used to calculate widht for given value/volume
  let width = value.toString().length
  width = value / ("1" + repeatString("0", count))
  return width
}

export function repeatString(str, times) {
  let result = "";
  for (let i = 0; i < times; i++) {
    result += str;
  }
  return result;
}


export async function updateTotal() {
  console.log("updating total")
  let quantity = $("#quantity").val()
  console.log("quanitty", quantity)
  let ltp = $("#ltp").html()
  console.log("ltp", ltp)
  $("#total").html((quantity * ltp).toFixed(2))
}



export async function showPositions() {
  // function used to create dynamic positions table and display it
  let position_data = await window.asthaApi.positions()
  console.log("position data", position_data)
  let position_data_array = position_data?.data?.day
  console.log("position data array", position_data_array)
  let data = await createTableFromJson(position_data_array, ["exchange", "symbol", "product", "quantity", "value"])
  $(".square-off-button").on("click", squareOffPosition)
  console.log("append completed of table")

  $("input.target,input.stoploss").on("input", function () {
    console.log("before calling target function")
    let token = $(this).attr("data-token")
    console.log("token", token)
    let ltp = $(`#ltp-${token}`).html()
    console.log("ltp", ltp)
    updateTSL(token, true, true)
    CheckTSL(token, ltp)

  })
}

export async function squareOffPosition(event) {
  console.log("button Clicked")
  event.stopPropagation(); // stop event bubbling
  console.log("clicked on open-position", $(this));
  let json_data = JSON.parse($(this).attr("data-json"))
  let token = json_data?.token
  console.log("json data from positoin and token", json_data, token)
  let ltp = $(`#ltp-${token}`).html()
  let target = $(`#target-${token}`).val()
  let stoploss = $(`#stoploss-${token}`).val()
  let checkTsl = $(`#set-tsl-${token}`).is(":checked")
  let trailPoint = $(`#trailPoint-${token}`).val()
  let trailPointSl = $(`#trailPointSl-${token}`).val()
  let buyPrice = $(`#buyPrice-${token}`).html()
  console.log("BuyPrice from squareOff", buyPrice)
  // alert("dummy update")
  let quantity = parseInt($(`#quantity-${token}`).val())
  console.log("target,stoploss,checktsl", target, stoploss, checkTsl)
  let form_data = formData()
  let buyq = json_data['buy_quantity']
  let sellq = json_data['sell_quantity']
  let transaction_type = buyq > sellq ? "SELL" : "BUY"  // json_data['variety']
  console.log($(`#position-square-off-modal-${token} input[name=price]`))
  let updated = await updateObject(json_data, form_data)
  console.log("updated object", updated)
  form_data['transaction_type'] = transaction_type
  form_data['quantity'] = quantity
  form_data['price'] = parseFloat(ltp)
  window.TSL[token] = {}
  window.TSL[token] = {
    target: target, stoploss: stoploss, checkTsl: checkTsl,
    trailPoint: trailPoint, trailPointSl: trailPointSl, dummyBuyPrice: buyPrice
  }

  if (target <= 0 && stoploss <= 0 && !checkTsl) {
    // when target is not set and stoploss is not set and checktsl is not checked
    // when no condition place square off order
    form_data['variety'] = "RL-MKT"
    let order_response = await window.asthaApi.placeOrder(form_data)
    Message("", "Square off order placed")
    Message("", order_response?.message)
    window.TSL[token] = {}
    return

  }
  else if (target > 0 && stoploss <= 0 && !checkTsl) {
    // when target is set and stoploss is not set and checktsl is not checked
    // place target order
    console.log("form data before target order", form_data)
    form_data.trigger_price = parseFloat(target)
    form_data.variety = "SL"
    console.log('form data after target order', form_data)
    form_data.price = form_data.trigger_price
    let order_response = await window.asthaApi.placeOrder(form_data)
    console.log("Target order response", order_response)
    Message("", "Target Order Placed")
    Message("", order_response?.message)
    window.TSL[token] = {}
    return
    // alert("place target order")
  }

  else if (target <= 0 && stoploss > 0 && !checkTsl) {
    // when target is not set and stoploss is  set and checktsl is  not checked
    // place stoploss order
    form_data['trigger_price'] = parseFloat(stoploss)
    form_data['variety'] = "SL"
    form_data.price = form_data.trigger_price
    let order_response = await window.asthaApi.placeOrder(form_data)
    console.log("Stoploss order response", order_response)
    Message("", "Stoploss Order Placed")
    Message("", order_response?.message)
    window.TSL[token] = {}
    return
    // alert("place stoploss order")
  }
  else if (target > 0 && stoploss > 0 && !checkTsl) {
    // when target is set and stoploss is set and checktsl is not checked
    // start CheckTSL function
    $(`#set-tsl-${token}`).prop("checked", true)
    // set dummy buy price for the first time
    $(`#trailPoint-${token}`).attr("data-dummybuyprice", buyPrice)
    Message("", `TSL Started with Target ${target} and Stoploss ${stoploss}`)
    window.TSL[token].checkTsl = true


    CheckTSL(token, ltp)
    // alert("start CheckTSL function")

  }

  // the only case left is when target and stoploss is set and checktsl is checked
  // in this case tsl need's to be updated
  if (checkTsl) {
    // when tsl is checked and target and stoploss is set means values are updated
    let dummyBuyPrice = $(`#trailPoint-${token}`).attr("data-dummybuyprice")
    window.TSL[token].dummyBuyPrice = dummyBuyPrice
    Message("", `TSL Updated with Target ${target} and Stoploss ${stoploss}`)

  }

  return true

  // let orderType = $(`#position-square-off-modal-${token} input[name=orderType]:checked`).val()


}









export async function ShowUsername() {
  let data = await chrome.storage.sync.get()
  let username = data.apiTokenData.data.user_name
  console.log("username from db", username, data)
  $("#username").html(username)
}

export async function updateObject(obj, args) {
  // function used to update object (form data basically)
  for (let key in args) {
    if (obj.hasOwnProperty(key)) {
      args[key] = obj[key];
    }
  }
  return args
}

export async function CheckTSL(token, ltp) {
  // function used to check target and stop loss and alert the user 
  ltp = parseFloat(ltp)
  let target_value = parseFloat($(`#target-${token}`).val())
  let stop_loss_value = parseFloat($(`#stoploss-${token}`).val())
  let trailPoint = parseFloat($(`#trailPoint-${token}`).val())
  let trailPointSl = parseFloat($(`#trailPointSl-${token}`).val())
  let dummyBuyPrice = parseFloat($(`#trailPoint-${token}`).attr("data-dummybuyprice"))
  // alert("dummy buy price", dummyBuyPrice)
  console.log("dummy buy price", dummyBuyPrice)
  let checkbox = $(`#set-tsl-${token}`).is(":checked")
  let quantity = parseInt($(`#quantity-${token}`).val())
  if (!checkbox) {
    console.log("TSL NOT SET")
    return

  }

  let order_data = await collectJson(token)
  if (!order_data) { return }
  order_data['quantity'] = quantity
  order_data['variety'] = "SL"


  if (target_value > 0 && checkbox && ltp >= target_value) {
    Message("Target Hit", "Target hit for token " + token)
    // check for trailing stop loss

    console.log("order data", order_data)
    order_data['trigger_price'] = parseFloat(target_value)
    order_data['price'] = parseFloat(target_value)
    let response = await window.asthaApi.placeOrder(order_data)
    Message("Target Hit", response?.message)
    $(`#set-tsl-${token}`).prop("checked", false)
    window.TSL[token] = {}
    await playSound()
    return
  }
  else if (stop_loss_value > 0 && checkbox && ltp <= stop_loss_value) {
    // if ltp < stop_loss_value then alert user
    console.log("stop loss hit")
    $(`#set-tsl-${token}`).prop("checked", false)
    console.log("order data", order_data)
    order_data['trigger_price'] = parseFloat(stop_loss_value)
    order_data['price'] = parseFloat(stop_loss_value)
    let response = await window.asthaApi.placeOrder(order_data)
    Message("", "StopLoss Hit")
    Message("", response?.message)
    window.TSL[token] = {}

    await playSound()
    return
    // await showPositions()
  }
  else if (checkbox && (trailPoint > 0) && (ltp >= dummyBuyPrice + trailPoint)) {
    // trail stop loss up and update dummy buy price
    console.log("trail stop loss up")
    dummyBuyPrice = trailPoint + dummyBuyPrice
    let oldStopLoss = parseFloat($(`#stoploss-${token}`).val())
    let newStopLoss = oldStopLoss + trailPointSl
    window.TSL[token].dummyBuyPrice = dummyBuyPrice
    $(`#trailPoint-${token}`).attr("data-dummybuyprice", dummyBuyPrice) // update dummy buy price
    $(`#stoploss-${token}`).val(newStopLoss)
    window.TSL[token].stoploss = newStopLoss
    Message("", `Trail Stoploss Updated to ${newStopLoss}`)



  }
}



export async function updatePositionPnl(token, ltp) {
  // function used to update pnl in open position for open position table only
  // used to update single position pnl

  // quantity will be > 0 or < 0 because it is in open position
  let quantity = parseInt($(`#quantity-${token}`).val())
  let buy_price = parseFloat($(`#buyPrice-${token}`).html())
  if (isNaN(buy_price)) {
    // if buy price is nan then  no open posiion for this token
    return

  }

  let ptype = $(`#ptype-${token}`).html()
  let current_pnl = parseFloat($(`#pnl-${token}`).html())
  let cpnl = 0
  let positionData = JSON.parse(localStorage.getItem(`position-${token}`))
  let lotSize = positionData.lot_size
  let netQty = 1
  console.log("position data", positionData, "currentPnl", current_pnl, 'Lotsize', lotSize, 'cpnl', cpnl)
  if (positionData.exchange === "NSE_FO") {  // when exchange is FNO
    netQty = positionData.quantity
  }
  else if (positionData.exchange === "MCX_FO") { // when exchange is MCX
    netQty = positionData.quantity * positionData.multiplier
  }
  else {
    netQty = positionData.quantity * positionData.lot_size
  }
  console.log("net quantity", netQty)

  if (ptype == "Long") {
    // when buy position is open
    // Gain = (last_trade_price * quantity * lot_size * multiplier +overnight_sell_value ) - overnight_buy_value
    // if (positionData.exchange === "MCX_FO") {

    //   cpnl = (ltp * positionData.quantity * positionData.lot_size * positionData.multiplier + positionData.overnight_sell_value) - positionData.overnight_buy_value
    // }
    // else {
    cpnl = (ltp - buy_price) * (netQty)
    // }

    console.log("cpnl for long position", cpnl)
    console.log("pnl for single long position", cpnl)
    $(`#pnl-${token}`).html(cpnl.toFixed(2))
  }
  else if (ptype == "Short") {
    // when sell position is open
    // Gain = overnight_sell_value - (overnight_buy_value + last_trade_price * quantity * lot_size * multiplier)
    // if (positionData.exchange === "MCX_FO") {

    //   cpnl = positionData.overnight_sell_value - (positionData.overnight_buy_value + ltp * positionData.quantity * positionData.lot_size * positionData.multiplier)
    // }
    // else {
    cpnl = (ltp - buy_price) * (quantity)
    // }
    console.log("pnl for single short position", cpnl)
    $(`#pnl-${token}`).html(cpnl.toFixed(2))
  }

  let color = current_pnl > cpnl ? "red" : "green"
  $(`#pnl-${token}`).css("color", color)


  updateUrpnl()

}


export async function updateUrpnl() {
  // function used to update urpnl from open position table for all tokens
  // update urpnl in main dialog box
  let oldUrPnl = parseFloat($("#urpnl").html())
  let urpnl = 0
  $(".position-pnl").each(function (index, item) {
    urpnl += parseFloat($(item).html())
  })
  $("#urpnl").html(parseFloat(urpnl.toFixed(2)))
  let color = oldUrPnl > urpnl ? "red" : "green"
  $("#urpnl").css("color", color)

}

export async function updateOrderBook() {
  window.asthaApi.getOrders(99999).then((data) => { orderBookTemplate(data?.orders) })
}

export async function orderBookTemplate(orderBook, tableSelector = '#order-book-table tbody') {
  // function used to return order book html template
  let table = $(`${tableSelector}`)
  table.empty() // remove the previous table body items
  if (orderBook.length === 0 || orderBook === undefined) {
    table.append(`<tr>
    <td>
      <div>No Data Found</div>
    </td>`)
    return
  }
  for (const item of orderBook) {
    console.log(" order Book Item ", item)
    let ltp = await window.asthaApi.quote(item.exchange, item.token, "ltp")
    ltp = ltp[`${item.exchange}-${item.token}`].last_trade_price
    // ltp =ltp.last_trade_price

    let symbolName = `${item?.symbol} ${item?.strike_price || ""} ${item?.expiry_date} ${item?.option_type}`
    table.append(`<tr>
    <td>
      <div>${symbolName}</div>
    </td>
    <td>
      <div>${item?.transaction_type}</div>
    </td>
    <td>
      <div class="${item?.token}"  id="ltp-${item?.token}" style="width:30px;">${ltp}</div>
    </td>
    <td>
      <input class="input-text order-book-quantity" type="number" data-token="15119" id="order-quantity-${item?.token}" value="${item?.total_quantity}" placeholder="${item?.total_quantity}" style="width: 50px;">
    </td>
    <td>
      <div>${item?.status}</div>
    <td>
      <select class="select-input trade-x" name="order-variety">
        <option value="${item?.variety}" class="trade-x"> ${item?.variety}</option>
        <option value="RL-MKT" class="trade-x">Market</option>
        <option value="RL" class="trade-x">Regular</option>
        <option value="SL" class="trade-x">Stop loss limit</option>
        <option value="SL-MKT" class="trade-x">Stop loss market</option>
      </select>
      <!-- -->
    </td>
    <td>
    <input class="input-text order-book-price" type="number" data-token=${item['token']} id="order-price-${item['token']}" value="${item?.order_price}"  placeholder="${item?.order_price}" style="width: 50px;">
    </td>
    <td><button  class="square-off-all-button-css square-off-button modify-order-button button" id="modify-order-button-${item['token']}"  data-token=${item['token']}  data-json='${JSON.stringify(item)}' >Modify Order</button></td>
    <td><button  class="trade-x square-off-all-button-css button cancel-order-button" id="cancel-order-button-${item['token']}"  data-token=${item['token']}  data-json='${JSON.stringify(item)}' >
    <svg class="w-6 h-6 trade-x text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m13 7-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
    </svg>
  </button></td>
  </tr>
    `)
    WsSubscribe(item["exchange"], item["token"], "full")
  }

  // because this button are created new every time
  $(".modify-order-button").on('click', ModifyOrderEvent)
  $(".cancel-order-button").on("click", CancelOrderEvent)
}

export async function collectJson(token, additionalUpdates = { "": "" }) {
  // function used to update the object data for given token
  let json_data = $(`#square-off-button-${token}`).attr("data-json")
  if (json_data === undefined) { return }
  json_data = JSON.parse(json_data)
  let form_data = formData()
  let quantity = $(`#quantity-${token}`).val()
  let ltp = $(`#ltp-${token}`).html()
  let transaction_type = json_data['buy_quantity'] > json_data['sell_quantity'] ? "SELL" : "BUY"
  console.log("form data and json data", form_data, json_data)
  let updated = await updateObject(json_data, form_data)
  console.log("first update", updated)
  updated = await updateObject(additionalUpdates, form_data)
  updated['transaction_type'] = transaction_type
  updated['quantity'] = parseInt(quantity)
  updated['price'] = parseFloat(ltp)
  updated['is_amo'] = JSON.parse($("#am").val())
  console.log("updated from collect json function", updated)
  return updated

}

export async function playSound() {
  // function used to play sound
  let audio = new Audio("https://cdn.pixabay.com/download/audio/2023/01/04/audio_02f9b24f04.mp3");
  await audio.play();
}

export async function initTSL() {
  // function used to intialize the TSL
  console.log("Inside init TSL")
  if (!window.TSL) {
    window.TSL = {}
  }
  let position_data = await window.asthaApi.positions()
  let position_data_array = position_data?.data?.day
  // position_data_array =
  //   [
  //     {
  //       "exchange": "NSE_EQ",
  //       "symbol": "Adani Ka Bhai",
  //       "expiry_date": "",
  //       "option_type": "",
  //       "token": 1660,
  //       "product": "MTF",
  //       "quantity": 1,
  //       "overnight_buy_value": 0.00,
  //       "overnight_sell_value": 0.00,
  //       "overnight_average_price": 0.00,
  //       "lot_size": 1,
  //       "multiplier": 1.00,
  //       "average_price": 380.00,
  //       "value": -380.00,
  //       "buy_value": 380.00,
  //       "sell_value": 0.00,
  //       "buy_quantity": 1,
  //       "sell_quantity": 0,
  //       "buy_price": 380.00,
  //       "sell_price": 0.00,
  //     },
  //     {
  //       "exchange": "NSE_EQ",
  //       "symbol": "Ambani ka Bhai",
  //       "expiry_date": "",
  //       "option_type": "",
  //       "token": 123,
  //       "product": "MTF",
  //       "quantity": 1,
  //       "overnight_buy_value": 0.00,
  //       "overnight_sell_value": 0.00,
  //       "overnight_average_price": 0.00,
  //       "lot_size": 1,
  //       "multiplier": 1.00,
  //       "average_price": 380.00,
  //       "value": -380.00,
  //       "buy_value": 380.00,
  //       "sell_value": 0.00,
  //       "buy_quantity": 1,
  //       "sell_quantity": 0,
  //       "buy_price": 380.00,
  //       "sell_price": 0.00,
  //     },
  //     {
  //       "exchange": "NSE_EQ",
  //       "symbol": "Musk ka Chacha",
  //       "expiry_date": "",
  //       "option_type": "",
  //       "token": 458,
  //       "product": "MTF",
  //       "quantity": 1,
  //       "overnight_buy_value": 0.00,
  //       "overnight_sell_value": 0.00,
  //       "overnight_average_price": 0.00,
  //       "lot_size": 1,
  //       "multiplier": 1.00,
  //       "average_price": 380.00,
  //       "value": -380.00,
  //       "buy_value": 380.00,
  //       "sell_value": 0.00,
  //       "buy_quantity": 1,
  //       "sell_quantity": 0,
  //       "buy_price": 380.00,
  //       "sell_price": 0.00,
  //     },
  //   ]
  if (position_data_array === undefined || position_data_array.length === 0) {
    return
  }
  position_data_array.forEach((position) => {
    window.TSL[position['token']] = { target: 0, stoploss: 0 }

  })
  console.log("TSL", window.TSL)
}

export default function evaluateExpression(expression) {
  // Split the expression into its components (operands and operator)
  const [operand1, operator, operand2] = expression.split(' ');

  // Convert operands to numbers (assuming they are numeric)
  const num1 = parseFloat(operand1);
  const num2 = parseFloat(operand2);

  // Check the operator and perform the comparison
  switch (operator) {
    case '>':
      return num1 > num2;
    case '>=':
      return num1 >= num2;
    case '<':
      return num1 < num2;
    case '<=':
      return num1 <= num2;
    case '==':
      return num1 === num2;
    case '!=':
      return num1 !== num2;
    default:
      throw new Error('Invalid operator');
  }
}





export async function updateTSL(token, target, stoploss) {
  // function used to update the tsl for given token
  console.log("Inside UPdate TSL")

  if (!window.TSL) {
    window.TSL = {}
  }
  if (!target && !stoploss) {
    // when value of target and stoploss is not passed
    console.log("Not TSL passed")
    return { target: window.TSL?.token?.target, stoploss: window.TSL?.token?.stoploss }
  }
  else if (target && stoploss) { // when value of target and stoploss is passed
    console.log("TSL passed")
    let target_value = parseFloat($(`#target-${token}`).val())
    let stop_loss_value = parseFloat($(`#stoploss-${token}`).val())
    window.TSL[token].target = target_value
    window.TSL[token].stoploss = stop_loss_value
    return { target: window.TSL?.token?.target, stoploss: window.TSL?.token?.stoploss }
  }
}


// export async function CancelOrderEvent() {
//   // function used to cancel order 
//   // this keyword is html button
//   let json_data = JSON.parse($(this).attr("data-json"))
//   let exchange = json_data?.exchange
//   let order_id = json_data?.order_id
// }


export async function ModifyOrderEvent() {
  console.log("event details for modify order", this)
  let json_data = JSON.parse($(this).attr("data-json"))
  let exchange = json_data?.exchange
  let order_id = json_data?.order_id
  let variety = $("select[name='order-variety']").val()
  let price = $(`#order-price-${json_data?.token}`).val()
  // quantity in order book is net quantity not traded quantity might create confusion when some quantity is traded
  // might need to use lot size and multiplier in case of MCX
  let quantity = $(`#order-quantity-${json_data?.token}`).val()
  console.log("variety", variety)
  let trigger_price = 0
  if (variety === "RL" || variety === "RL-MKT") {
    trigger_price = 0
  }
  else {
    trigger_price = price
  }
  let updateData = {
    "variety": variety,
    "quantity": parseInt(quantity),
    "traded_quantity": parseInt(json_data?.traded_quantity),
    "price": parseFloat(price),
    "trigger_price": parseFloat(trigger_price),
    "disclosed_quantity": 0,
    "validity": "DAY",
    "validity_days": 1
  }

  let modifyResponse = await window.asthaApi.modifyOrder(exchange, order_id, updateData)
  Message("Modify Order", modifyResponse?.message)

}

export function normalizeToRange(value, minInput, maxInput, minOutput, maxOutput) {
  return Math.log(value + 1) / Math.log(1.4);
  return ((value - minInput) / (maxInput - minInput)) * (maxOutput - minOutput) + minOutput;
}

// const normalizedValue = normalizeToRange(originalValue, 0, 117370800, 0, 50);

export function updateStatus(status) {
  // function used to update user status
  $("#user-status").html(status)
  $("#user-svg").css("fill", status.toLowerCase() === "online" ? "green" : "red")
}

export function WsSubs(token, action = "get", status = false) {
  // function used to check if  a token is suscribed or not to websocket
  if (action === "set") {
    // update the status of token
    return sessionStorage.setItem("ws" + token, status)
  }
  else if (action === "get") {
    let response = sessionStorage.getItem("ws" + token)
    console.log("storage response", response)
    if (response === null || response === 'false') {
      return false
    }
    else {
      return true
    }
  }


}

export function WsSubscribe(exchange, token, mode = "full") {
  let response = WsSubs(token, "get")
  console.log("ws storage response", response)
  if (!response) {
    window.wire.subscribe(exchange, token, mode)
    WsSubs(token, "set", true)
  }
  else {
    console.log("already subscribed")
  }
}