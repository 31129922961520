import { createAction } from "redux-actions";
import _ from "lodash"

export const setHoldingsRdxConst = "SET_HOLDINGS";

export const setHoldingsAction = createAction(setHoldingsRdxConst, (payload) => payload);

export const setHoldingsReducer = (state = { holding: {} }, action) => {
    switch (action.type) {
        case setHoldingsRdxConst:
            return { ...state, ..._.cloneDeep(action.payload) };
        default:
            return state;
    }
};

// Path: react_app/src/redux/funds/setFundsAction.js