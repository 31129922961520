import { createAction } from "redux-actions";

const setDragPositionRdxCnst = "SET_DRAGPOSITION";

export const setDragPositionAction = createAction(
  setDragPositionRdxCnst,
  (payload) => payload
);

export const setDragPositionReducer = (
  state = { dragPosition: { x: 0, y: 0 } },
  action
) => {
  switch (action.type) {
    case setDragPositionRdxCnst:
      console.log(action.payload, "from reducer");
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
