/* eslint-disable no-restricted-globals */
/* global chrome */
/* global $ */
/* eslint-disable no-undef */
import { useDispatch, useSelector } from "react-redux"
import { message } from "antd"
import { appStore } from "../redux/store";
import { setProfileAction, setTokenAction } from "../redux/profile/setProfileAction"
import { setSearchResultsAction } from "../redux/searchResults/setSearchResultsAction";
import startWebsocket from "../utils/websocket"
import AsthaApi from "../js/apiCalls";
import { API_BASE_URL } from "../js/apiCalls";
import { getData, saveData } from "../js/helper";
var asthaApi = new AsthaApi(null, null);
window.asthaApi = asthaApi




export async function IronEdgeLogin() {
  const dispatch = appStore.dispatch
  const clientCode = appStore.getState().profile.clientCode;
  const password = appStore.getState().profile.password;

  var responseBody = {
    status: "error",
    detail: "Error Message"
  }

  try {
    let url = new URL(API_BASE_URL + 'auth/login');
    url.search = new URLSearchParams({ email: clientCode, password: password }).toString();
    let IronEdgeResponse = await fetch(url, {
      method: 'POST',
    });

    IronEdgeResponse = await IronEdgeResponse.json();
    console.log("IronEdge login IronEdgeResponse", IronEdgeResponse);

    if (IronEdgeResponse?.detail) {
      responseBody.status = "error";
      responseBody.detail = IronEdgeResponse?.detail;
      return responseBody;
    }

    let IronEdgeAccessToken = IronEdgeResponse.access_token
    let response = await fetch(API_BASE_URL + 'session/rupeezy', {
      headers: {
        "Authorization": `Bearer ${IronEdgeAccessToken}`
      },
      method: 'POST'
    })

    response = await response.json()
    if (response?.detail) {
      responseBody.status = "error";
      responseBody.detail = response?.detail;
      return responseBody;
    }

    console.log("Seesion Token Response", response)


    appStore.dispatch(setTokenAction({ "tempToken": response }));

    window.mfaType = response[0].mfaType;
    const accessToken = response;
    console.log("getToken respones", accessToken);
    // appStore.dispatch(setTokenAction({ "loginStatus": true }));
    // appStore.dispatch(setTokenAction({ "tempToken": [...response] }));
    // $("#otp").attr({ "placeholder": "Enter " + window.mfaType })
    // $("#GenerateOtp").html(response[0].message)
    window.asthaApi = new AsthaApi(accessToken[1].data.access_token, accessToken[0].data.access_token);
    await saveAccessToken(accessToken)
    console.log('access token from local', await getData("apiTokenData"));

    message.success("Welcome " + accessToken[1].data.user_name);
    console.log("astah api global", window.asthaApi)
    window.asthaApi.getFunds()
    window.asthaApi.positions()
    window.asthaApi.getOrders()
    window.asthaApi.holdings()
    // window.asthaApi.getNotifications()
    // window.asthaApi.pnl()
    // window.asthaApi.webSocket()
    // startWebsocket(accessToken[1].data.access_token)
    window.asthaApi.getOptionChain("NIFTY")


    return accessToken;
  } catch (error) {
    console.error('Error in GenerateOtp:', error);
    // Handle the error as needed
  }
}

export async function ClearSession() {
  // function to clear session
  await chrome.storage.sync.clear()
}



export async function validateTokenWrapper(token = "",) {
  // function to validate token
  await attachEvents()
  asthaApi = new AsthaApi(null, null)
  return await asthaApi.validateToken(token)

}

async function saveAccessToken(token) {
  // function to save access token from azure func
  console.log("saving token", token)
  await saveData({
    'webTokenData': token[0],
    "apiTokenData": token[1],
    "access_token": token[1].data?.access_token,
    "webAccessToken": token[0].data.access_token,
    "last_updated": Date(Date.now() * 1000)
  });
  console.log("access token saved", await getData("access_token"))
  // $("#login").text("Login Status :" + token[0]?.status)
  console.log("Session Saved Succesfully", token)
}

export async function autoStrike(symbolName = "nifty", optionType = "CE") {
  // function to auto select strike price
  const storage = {
    "nifty": { token: 26000, exchange: "NSE_EQ" },
    "banknifty": { token: 26009, exchange: "NSE_EQ" },
    "finnifty": { token: 26037, exchange: "NSE_EQ" },
  }

  var ltp = parseInt(await window.asthaApi.getLtp(
    storage[symbolName].exchange,
    storage[symbolName].token
  ))
  ltp = ltp - (ltp % 100) // to round off to nearest 100

  let response = await window.asthaApi.symbolSearch(`${symbolName} ${ltp} ${optionType}`)
  console.log("auto strike response", response)
  let originalObject = response[0]
  appStore.dispatch(setSearchResultsAction({
    selectSearchResult: {
      _source: {
        sSecurityDesc: originalObject._source.sSecurityDesc,
        ExchangeSegment: originalObject._source.ExchangeSegment,
        nToken: originalObject._source.nToken,
        quantity: originalObject._source.nRegularLot * 1
      },
    }
  }));
  console.log("action dispatched", response)
  return response
}

export async function playSound() {
  // function to play sound
  let audio = new Audio('https://cdn.pixabay.com/download/audio/2023/01/04/audio_02f9b24f04.mp3');
  audio.play();
}

export async function attachEvents() {
  console.log("inside attach events function");
  document.addEventListener("keydown", async function (event) {
    // Check if the combination of Ctrl key and "i" key is pressed
    if (event.ctrlKey && event.keyCode === 73) {
      console.log("ctrl + i pressed");
      document.querySelector("#react-chrome-app").style.display === "block"
        ? (document.querySelector("#react-chrome-app").style.display = "none")
        : (document.querySelector("#react-chrome-app").style.display = "block");
      return
    }
    if (event.keyCode === 119) {
      message.warning("Closing All Open Positions");
      await window.asthaApi.closeAllPositions()
      return
    }
    return false
  });
}





export function html_popup_function() {


  fetch(API_BASE_URL).then((response) => { console.log(response) }).catch((error) => { console.log("error", error) })
  $(".login-container input[type='checkbox']").on("click", function () { let type = $("#password").attr("type") === "password" ? "text" : "password"; $("#password").attr("type", type); })
  $("#GenerateOtp").on("click", async function () {
    $(this).empty()
    // $(this).css({"left":"36%"})
    $(this).append(`<svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>`)

    let clientCode = $("#clientCode").val()
    let password = $("#password").val()
    window.tempToken = await IronEdgeLogin(clientCode, password)
    await playSound()
    $("#login").toggleClass("disabled")


  })

  $("#sendSMS").on("click", SendSms)
  // to send sms after temp token generation
  $("#login").on("click", async function () {
    let otp = $("#otp").val()
    window.tempToken.push({ "otp": otp })
    let token = await getToken(window.tempToken)
    console.log("token", token)
    alert(JSON.stringify(token[0]?.status))
    if (token[0]?.status === "success") { // when login is successfull
      let response = await saveAccessToken(token)
      location.reload()
    }

  }
  )

  $("#logout").on('click', async () => {
    // this will work only in context of chrome storage
    let response = await chrome.storage.sync.clear()
    alert("Logout Successfull")
    location.reload()
  })
}











