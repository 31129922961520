import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { FiExternalLink } from "react-icons/fi";

import "./Portfolio.css";
import { Position } from "./PositionsComponent.jsx";
import { NavigationItems } from "../Navigation/NavigationItems.jsx";
import Draggable from "react-draggable";
import { useDragContext } from "../../App.js";

export default function PortfolioComponent() {
  const [currentTab, setCurrentTab] = useState("Positions");
  const { dragPosition, handleDrag } = useDragContext();

  const handleTabChange = async (tab) => {
    setCurrentTab(tab);
    await window.asthaApi.positions()
  };

  return (
    <Draggable position={dragPosition} onDrag={handleDrag}>
      <div className="portfolio-page">
        <div className="page-container">
          {/* header */}
          <div className="portfolio-header">
            <div className="header-items">
              {/* items */}
              <div className="header-item">
                <h4>NIFTY</h4>
                <p className="ltp-26000">
                  20966.40 <span> -30.70(-0.15%)</span>
                </p>
              </div>
              <div className="header-item">
                <h4>BANKNIFTY</h4>
                <p className="ltp-26009">
                  20966.40 <span> -30.70(-0.15%)</span>
                </p>
              </div>
              <FaChevronDown className="header-icon" size={18} />
            </div>

            {/* tabs */}
            <div className="tabs">
              {/* <div
                className={`tab ${
                  currentTab === "Investments" ? "active" : ""
                }`}
                onClick={() => handleTabChange("Investments")}
              >
                <p>Investments</p>
              </div> */}

              <div
                className={`tab ${currentTab === "Positions" ? "active" : ""}`}
                onClick={() => handleTabChange("Positions")}>
                <p>Positions</p>
              </div>
            </div>
          </div>

          {/* window.asthaApi.positions() && */}
          <Position />
        </div>
        <NavigationItems />
      </div>
    </Draggable>
  );
}
