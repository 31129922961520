import { createAction } from "redux-actions";
export const setPnlRdxConst = "SET_PNL";

export const setPnlAction = createAction(setPnlRdxConst, (payload) => payload);

export const setPnlReducer = (state = { pnl: { } }, action) => {
    switch (action.type) {
        case setPnlRdxConst:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

// Path: react_app/src/redux/funds/setFundsAction.js