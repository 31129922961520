import Draggable from "react-draggable";
import { useDragContext } from "../../App";
import { Link } from "react-router-dom";
import { HiArrowLeft } from "react-icons/hi2";
import "./Notifications.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MdNotifications } from "react-icons/md";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { setNotificationsAction } from "../../redux/notifications";

export const Notifications = () => {
  const { dragPosition, handleDrag } = useDragContext();
  const dispatch = useDispatch();

  const notificationRdx = useSelector((state) => state.notifications.notifications)

  const formatDate = (created_at) => {
    const createdAt = created_at;
    const date = new Date(createdAt);

    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };
  
  return (
    <Draggable position={dragPosition} onDrag={handleDrag}>
      <div className="notifications-page">
        <div className="page-container">
          <div className="notifications-header">
            {/* back arrow */}
            <Link to={"/trade"}>
              <HiArrowLeft className="header-icon" size={25} />
            </Link>

            <h2 className="page-title">Notifications</h2>

            <div className="header-icon">
              <MdNotifications size={25} className="svg" />
              <p>{notificationRdx?.length}</p>
            </div>
          </div>
          <div className="notification-cards">
            {notificationRdx?.map((notification) => {
              return (
                <div className="notification-card" key={notification.id}>
                  <div className="notification-content">
                    <p className="notification-text">{notification.content}</p>
                    <span className="notification-sender">
                      {notification.sender}
                    </span>
                  </div>
                  <div className="notification-date">
                    <p>{formatDate(notification.created_at)}</p>
                  </div>
                </div>
              );
            })}
          </div>

          {notificationRdx?.length === 0 && (
            <div className="content-page">
              <div className="image-container">
                <img
                  src="https://cdn-icons-png.flaticon.com/128/8933/8933839.png"
                  alt="shares"
                  height={150}
                  width={150}
                />
              </div>
              <div className="text-container">
                <p>Your Notifications will appear here</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Draggable>
  );
};
