import React, { useState } from 'react';
import './keyboard.css';
import { BiUpArrowAlt, BiSolidDownArrowAlt } from "react-icons/bi";

export const KeyBoardShortcut = () => {
  const [showPopup, setShowPopup] = useState(false);

  const showPopupForThreeSeconds
    = () => {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    };

  return (
    <div className='keyboard-shortcut-container'>
      <button onClick={showPopupForThreeSeconds} className='question-btn'>?</button>
      {showPopup && (
        <div className="keyboard-popup">
          <div className="keyboard-section-title">
            <p>Keyboard shortcuts</p>
          </div>
          <div className="keyboard-shortcuts">
            <div className="shortcut-item">
              <span>Ctrl + I</span>
              <span>Hides app</span>
            </div>
            <div className="shortcut-item">
              <span>↑ (Up Arrow)</span>
              <span>Place BUY order</span>
            </div>
            <div className="shortcut-item">
              <span>↓ (Down Arrow)</span>
              <span>Place SELL order</span>
            </div>
            <div className="shortcut-item">
              <span>F8</span>
              <span>Close All Positions</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
