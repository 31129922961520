import React, { useState } from "react";
import { useSelector } from "react-redux";
import { HiArrowLeft } from "react-icons/hi2";

import "./Funds.css";
import Draggable from "react-draggable";
import { Link } from "react-router-dom";
import { useDragContext } from "../../App";

// const wallet = chrome.runtime.getURL(
//   "./react_app/src/components/funds/wallet.svg"
// );

export const FundsComponent = () => {
  const funds = useSelector((state) => state.funds);
  console.log("funds", funds);
  const { dragPosition, handleDrag } = useDragContext();

  const categories = Object.keys(funds.funds);
  const [currentCategory, setCurrentCategory] = useState("nse");
  console.log(currentCategory);

  const handleCategoryOnChange = (e) => {
    setCurrentCategory(e.target.value);
  };

  return (
    <Draggable position={dragPosition} onDrag={handleDrag}>
      <div className="funds-page">
        <div className="page-container">
          <div className="funds-header">
            {/* back arrow */}
            <Link to={"/trade"}>
              <HiArrowLeft className="header-icon" size={25} />
            </Link>
            <div className="top-flex">
              <h1 className="page-title">Fund Details</h1>

              <select
                name="funds"
                className="funds-category"
                onChange={handleCategoryOnChange}>
                {categories.map((category, indx) => (
                  <option value={category} key={indx}>
                    {category.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* middle cards */}
          <div className="fund-cards">
            <div className="middle-cards">
              <div className="middle-card-1">
                <p>Deposit</p>
                <span>₹ {funds.funds[currentCategory].deposit}</span>
              </div>
              <div className="middle-card">
                <p>Funds Transfer</p>
                <span>₹ {funds.funds[currentCategory].funds_transferred}</span>
              </div>
              <div className="middle-card-1">
                <p>Collateral</p>
                <span>₹ {funds.funds[currentCategory].collateral}</span>
              </div>
              <div className="middle-card">
                <p>Credit For Sale</p>
                <span>₹ {funds.funds[currentCategory].credit_for_sale}</span>
              </div>
              <div className="middle-card-1">
                <p>Option CFS</p>
                <span>
                  ₹ {funds.funds[currentCategory].option_credit_for_sale}
                </span>
              </div>
              <div className="middle-card">
                <p>Limit Utilization</p>
                <span>₹ {funds.funds[currentCategory].limit_utilization}</span>
              </div>
              <div className="middle-card-1">
                <p>Funds Withdrawl</p>
                <span>₹ {funds.funds[currentCategory].funds_withdrawn}</span>
              </div>
              <div className="middle-card">
                <p>MTM + Booked Loss</p>
                <span>
                  ₹ {funds.funds[currentCategory].mtm_and_booked_loss}
                </span>
              </div>
              <div className="middle-card-1">
                <p>
                  Booked Profit <br /> (Not Part of Net Available Calcualation)
                </p>
                <span>₹ {funds.funds[currentCategory].booked_profit}</span>
              </div>
            </div>
            <div className="middle-card-1">
              <p>Total Trading Power</p>
              <span>₹ {funds.funds[currentCategory].total_trading_power}</span>
            </div>
            <div className="middle-card">
              <p>Total Utilization</p>
              <span>₹ {funds.funds[currentCategory].total_utilization}</span>
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};
