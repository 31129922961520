import "./Closed.css";
import { IoSearchOutline } from "react-icons/io5";
import { HiDownload } from "react-icons/hi";
import { MdFilterList } from "react-icons/md";
import { useSelector } from "react-redux";
import { formatTime } from "../../utils.js";

export const Closed = () => {
  const positions = useSelector((state) => state.positions);
  console.log("orders from redux", positions);
  const orders = positions.orders.orders;

  const closedAndRejectedOrders = orders?.filter(
    (order) => order.status !== "PENDING"
  );

  return (
    <>
      {closedAndRejectedOrders && closedAndRejectedOrders.length > 0 ? (
        <div className="closed-page">
          <div className="page-container">
            {/* top container */}
            <div className="closed-top-container">
              <div className="input-div">
                <IoSearchOutline className="search-icon" size={20} />
                <input type={"text"} placeholder="Search shares, stocks, .." />
              </div>
              <div className="top-container-icon">
                <HiDownload size={20} />
              </div>
              <div className="top-container-icon">
                <MdFilterList size={20} />
              </div>
            </div>
            <div
              className={`closed-orders-container ${
                closedAndRejectedOrders.length > 4 ? "active" : ""
              }`}>
              {closedAndRejectedOrders.map((order, indx) => (
                <div className="closed-card" key={indx}>
                  {/* left */}
                  <div className="left">
                    <span>{formatTime(order.order_created_at)}</span>
                    <h3>{`${order.symbol} ${"" || order?.strike_price} ${
                      order?.expiry_date
                    }  `}</h3>
                    <p>
                      NSE <i>{order.transaction_type}</i>{" "}
                      {order.pending_quantity}/{order.total_quantity} Shares
                    </p>
                  </div>
                  {/* right side */}
                  <div className="right">
                    <span>{order?.variety}</span>
                    <h3>{order.order_price}</h3>
                    <p>{order.status}</p>
                  </div>
                </div>
              ))}

              <div className="closed-orders-info">
                <p>
                  This are your completed, canceled or rejected orders from
                  today.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="content-page">
          <div className="image-container">
            <img
              src="https://cdn-icons-png.flaticon.com/128/1090/1090965.png"
              alt="shares"
              height={150}
              width={150}
            />
          </div>
          <div className="text-container">
            <p>Your closed orders from today will appear here</p>
          </div>
        </div>
      )}
    </>
  );
};
