import { useEffect, useState } from "react";
import "./Portfolio.css";
import { useSelector, useDispatch } from "react-redux";
import { Modal, message } from "antd";
import { FaEye } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { MdClose } from "react-icons/md";
import { setTSLAction } from "../../redux/Tsl/setTslAction";

export const Position = () => {
  const dispatch = useDispatch();
  const positions = useSelector((state) => state.positions);
  const tslDataRdx = useSelector((state) => state.tsl);
  let PnlObject = useSelector((state) => state.pnl);
  PnlObject = PnlObject.pnl;
  console.log("position data", positions)
  const dayPositions = positions?.data?.day;
  const [ltp, setLtp] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalLTP, setModalLTP] = useState(undefined);
  const [isEditing, setIsEditing] = useState(false);

  const [TSLData, setTSLData] = useState({});

  const handleTslValueChange = (token, field, value) => {
    setTSLData((prevData) => ({
      ...prevData,
      [token]: { ...prevData[token], [field]: value },
    }));
  };

  console.log("tsl data after update", tslDataRdx);

  useEffect(() => {
    const fetchLtp = async () => {
      try {
        const ltpsArray = await Promise.all(
          dayPositions.map(async (position) => {
            const ltpValue = await window.asthaApi.getLtp(
              position.exchange,
              position.token
            );
            return ltpValue;
          })
        );
        setLtp(ltpsArray);
      } catch (error) {
        console.error("Error fetching LTPs", error);
      }
    };
    if (dayPositions) {
      fetchLtp();
    }
  }, [dayPositions]);

  useEffect(() => {
    dayPositions.map((position) => {
      const existingTSLData = tslDataRdx[position.token] || {};
      console.log(existingTSLData, "existing tsl data");
      const newTSLData = {
        stock_name: position.symbol || "🚫",
        long_short: position.quantity,
        buy_price: position.buy_price || "",
        QTY: position.lot_size || "",
        LTP: modalLTP || "",
        loss_profit: "",
        target: existingTSLData.target || "",
        Stoploss: existingTSLData.Stoploss || "",
        trail_point: "",
        trail_point_sl: "",
        data: position,
        ...existingTSLData,
      };
      console.log("new tsl data", newTSLData);

      dispatch(setTSLAction({ [position.token]: newTSLData }));
      setTSLData((prevData) => ({
        ...prevData,
        [position.token]: newTSLData,
      }));
    });
  }, [dayPositions]);

  const showModal = (position, index) => {
    setSelectedPosition(position);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    if (selectedPosition) {
      dispatch(
        setTSLAction({
          [selectedPosition.token]: TSLData[selectedPosition.token],
        })
      );
    }
    setSelectedPosition(null);
    setIsModalVisible(false);
  };

  return (
    <>
      {dayPositions.length > 0 && dayPositions ? (
        <div>
          <div
            className={`positions-container ${dayPositions.length > 5 ? "active" : ""
              }`}>
            {dayPositions?.map((position, index) => (
              <div className="position-card" key={position.token}>
                <div className="left">
                  <span className="time">{position.product}</span>
                  <h3>{`${position.symbol} ${position.expiry_date} ${position.option_type}`}</h3>
                  <p>
                    <span>
                      {position.multiplier *
                        position.lot_size *
                        position.quantity}{" "}
                    </span>
                    Shares
                  </p>
                </div>
                <div className="pnl-container">
                  <div>
                    <p>PNL:</p>
                    <span>{PnlObject[position.token]?.pnl.toFixed(2)}</span>
                  </div>
                  <button
                    className="position-square-off-btn"
                    onClick={() => {
                      window.asthaApi
                        .placeOrder({
                          exchange: position.exchange,
                          token: position.token,
                          transaction_type:
                            position.quantity > 0 ? "SELL" : "BUY",
                          product: "INTRADAY",
                          variety: "RL-MKT",
                          quantity:
                            Math.abs(position.quantity) * position.lot_size,
                          price: 0,
                          trigger_price: 0,
                          disclosed_quantity: 0,
                          validity: "DAY",
                          validity_days: 1,
                          is_amo: false,
                        })
                        .then((res) => {
                          message.success(res.status + " " + res.message);
                        });
                    }}>
                    <IoMdClose />
                    {/* square off */}
                  </button>
                </div>
                <div className="right">
                  <span>{position.product}</span>
                  <h3>
                    <FaEye
                      size={25}
                      onClick={() => {
                        showModal(position, index);
                      }}
                      className="show-trails"
                    />
                  </h3>
                </div>
              </div>
            ))}
            <div className="positions-info">
              <p>This are your positions from today.</p>
            </div>
          </div>
          <Modal
            title="TSL Modal"
            visible={isModalVisible}
            onCancel={handleCancel}
            width={700}
            footer={null}>
            <div className="modal-tsl-container">
              <div className="edit-btn">
                {isEditing ? (
                  <MdClose
                    size={25}
                    onClick={() => {
                      setIsEditing(false);
                      handleCancel();
                    }}
                    className="edit-close"
                  />
                ) : (
                  <CiEdit
                    size={25}
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    className="edit-open"
                  />
                )}
              </div>
              <div className="position-table-container">
                <table className="tsl-info-table">
                  <thead className="position-table-head">
                    <th className="table-header">Exchange</th>
                    <th className="table-header">Stock Name</th>
                    <th className="table-header">long short</th>
                    <th className="table-header">LTP</th>
                    <th className="table-header">Buy Price</th>
                    <th className="table-header">QTY</th>
                    {/* <th className="table-header">Loss/profit</th> */}
                    <th className="table-header">target</th>
                    <th className="table-header">Stoploss</th>
                    <th className="table-header">Trail point</th>
                    <th className="table-header">Trail point SL</th>
                  </thead>
                  <tbody className="position-table-body">
                    {selectedPosition && (
                      <tr>
                        <td>{TSLData[selectedPosition.token]?.exchange}</td>
                        <td>{TSLData[selectedPosition.token]?.stock_name}</td>
                        <td>{TSLData[selectedPosition.token]?.long_short}</td>
                        <td className={"ltp-" + selectedPosition.token}>
                          {TSLData[selectedPosition.token]?.LTP}
                        </td>
                        <td>{TSLData[selectedPosition.token]?.buy_price}</td>
                        <td>{TSLData[selectedPosition.token]?.QTY}</td>
                        {/* <td>{TSLData[selectedPosition.token]?.loss_profit}</td> */}
                        <td>
                          {isEditing ? (
                            <input
                              type="text"
                              className="tsl-edit-input"
                              value={TSLData[selectedPosition.token]?.target}
                              onChange={(e) =>
                                handleTslValueChange(
                                  selectedPosition.token,
                                  "target",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <span>
                              {TSLData[selectedPosition.token]?.target}
                            </span>
                          )}
                        </td>
                        <td>
                          {isEditing ? (
                            <input
                              type="text"
                              className="tsl-edit-input"
                              value={TSLData[selectedPosition.token]?.Stoploss}
                              onChange={(e) =>
                                handleTslValueChange(
                                  selectedPosition.token,
                                  "Stoploss",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <span>
                              {TSLData[selectedPosition.token]?.Stoploss}
                            </span>
                          )}
                        </td>
                        <td>
                          {" "}
                          {isEditing ? (
                            <input
                              type="text"
                              className="tsl-edit-input"
                              value={
                                TSLData[selectedPosition.token]?.trail_point
                              }
                              onChange={(e) =>
                                handleTslValueChange(
                                  selectedPosition.token,
                                  "trail_point",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <span>
                              {TSLData[selectedPosition.token]?.trail_point}
                            </span>
                          )}
                        </td>
                        <td>
                          {isEditing ? (
                            <input
                              type="text"
                              className="tsl-edit-input"
                              value={
                                TSLData[selectedPosition.token]?.trail_point_sl
                              }
                              onChange={(e) =>
                                handleTslValueChange(
                                  selectedPosition.token,
                                  "trail_point_sl",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <span>
                              {TSLData[selectedPosition.token]?.trail_point_sl}
                            </span>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <div className="main-content">
          <div className="image-container">
            <img
              src="https://cdn-icons-png.flaticon.com/128/10655/10655591.png"
              alt="shares"
              height={150}
              width={150}
            />
          </div>
          <div className="text-container">
            <p>Your positions from today will appear here</p>
          </div>
        </div>
      )}
    </>
  );
};
