import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import * as Sentry from "@sentry/react"; // Corrected import for Sentry
import { BrowserTracing } from "@sentry/tracing"; // Corrected import for BrowserTracing
import App from "./App.js";

// Sentry.init({
//   dsn: "https://e342446f76cb800e9c6d75b266fd7264@o4505906695766016.ingest.sentry.io/4506518775857152",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     }),
//     // Ensure 'Replay' is correctly imported and used here if it's part of the Sentry package you're using
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const rootElement = document.createElement("div");
rootElement.id = "react-chrome-app";
document.body.appendChild(rootElement);

const root = ReactDOM.createRoot(document.getElementById("react-chrome-app"));
root.render(<App />);

reportWebVitals();