import Draggable from "react-draggable";
import "./SearchPage.css";
import { useNavigate, Link } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { GoArrowLeft } from "react-icons/go";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CiCirclePlus } from "react-icons/ci";
import { Spin, message } from "antd";
import { FaLink } from "react-icons/fa";

import { setSearchResultsAction } from "../../redux/searchResults/setSearchResultsAction.js";
import { useDragContext } from "../../App.js";

export const SearchPageComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dragPosition, handleDrag } = useDragContext();

  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchSuccess, setIsSearchSuccess] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);

  const getSearchResults = async (searchTerm) => {
    setSearching(true); // this is setting the searching to true so that loader can be enabled after searching
    if (searchTerm !== "") {
      const data = await window.asthaApi.symbolSearch(searchTerm);
      console.log(data);
      setSearching(false); // this is setting the searching to false so that loader can be disabled
      setSearchResults(data);
      setIsSearchSuccess(true);
    } else {
      setSearching(false);
    }
  };

  useEffect(() => {
    const inputElement = document.querySelector(".search-input-div input");
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  function filterFields(originalObject) {
    const filteredObject = {
      _source: {
        sSecurityDesc: originalObject._source.sSecurityDesc,
        ExchangeSegment: originalObject._source.ExchangeSegment,
        nToken: originalObject._source.nToken,
        quantity: originalObject._source.nRegularLot * 1,
      },
    };
    return filteredObject;
  }

  const handleSelectSearchCard = (resultCard) => {
    const filteredResult = filterFields(resultCard);
    dispatch(setSearchResultsAction({ selectSearchResult: filteredResult }));
    console.log("saving card data", resultCard);
    navigate("/trade");
  };

  return (
    <Draggable position={dragPosition} onDrag={handleDrag}>
      <div className="search-page">
        <div className="container">
          <div className="search-header">
            <Link to={"/trade"}>
              <GoArrowLeft size={24} className="back-icon" />
            </Link>

            <div className="search-input-div">
              <input
                type={"text"}
                placeholder="Search shares, stocks, .."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  getSearchResults(searchTerm);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    getSearchResults(searchTerm);
                    dispatch(
                      setSearchResultsAction({
                        selectSearchResult: filterFields(searchResults[0]),
                      })
                    );
                    navigate("/trade");
                  }
                }}
              />
              <IoSearchOutline
                className="search-icon"
                size={24}
                onClick={() => getSearchResults(searchTerm)}
              />
            </div>
          </div>

          {/* showing loader only when the searching state is true */}
          {searching ? (
            <div className="loader-container">
              <Spin size="large" />
            </div>
          ) : null}

          {/* search-results container */}
          {isSearchSuccess && searchResults ? (
            <div className="search-results-container">
              <p className="result-text">
                results for <span>{searchTerm}</span>
              </p>

              <div className="result-cards">
                {searchResults?.slice(0, 50).map((result, indx) => (
                  <div
                    className="result-card"
                    key={indx}
                    onClick={() => handleSelectSearchCard(result)}>
                    <div className="left-side">
                      <h5>{result._source.sSymbol}</h5>
                      <h6>
                        {result._source.sExchange} :{" "}
                        <span>{`${result._source.sSymbolnew} ${result._source.nStrikePrice1} ${result._source.nExpiryDate1} ${result._source.sOptionType} `}</span>
                      </h6>
                    </div>
                    <FaLink
                      style={
                        result._source.nFOExists
                          ? { color: "orange" }
                          : { color: "red" }
                      }
                      onClick={() => {
                        window.asthaApi.getOptionChain(result._source.sSymbol);
                        navigate("/option-chain");
                      }}></FaLink>
                    {/* <CiCirclePlus size={24} className="show-icon" /> */}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Draggable>
  );
};
