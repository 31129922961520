import { createAction } from "redux-actions";
import _ from "lodash"

const setNotifications = "SET_NOTIFICATIONS";

export const setNotificationsAction = createAction(
  setNotifications,
  (payload) => payload
);

export const setNotificationsReducer = (
  state = { notifications: [] },
  action
) => {
  switch (action.type) {
    case setNotifications:
      return {
        ...state,
        notifications: _.cloneDeep(action.payload.notifications)
      };
    default:
      return state;
  }
};
