/* chrome */
import React, { useEffect, useState, useRef } from "react";
import Draggable from "react-draggable";
import "./Register.css";
import { MdOutlineLogin } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { validateTokenWrapper } from "../../external_js/html_popup";
import { useNavigate } from "react-router-dom";
import { setDragPositionAction } from "../../redux/dragPosition/setDragPosition";
import { useDragContext } from "../../App";
// import AsthaApi from "../../../js/apiCalls";

export default function RegisterComponent() {
  const user = useSelector((state) => state.profile.name);
  const [login, setLogin] = useState(false);
  const [Display, setDisplay] = useState("block");
  const dispatch = useDispatch();
  const { dragPosition, handleDrag } = useDragContext();
  useEffect(() => {
    validateTokenWrapper().then((data) => {
      console.log("token validation response", data);
      setLogin(data);
    });
  }, []);
  // const tokenValidated = validateTokenWrapper();
  // console.log("token validation", tokenValidated);
  const navigate = useNavigate();
  console.log("DRAGPOSITION FROM Context", dragPosition);

  // document.addEventListener("keydown", hideApp);

  return !login ? (
    <Draggable position={dragPosition} onDrag={handleDrag}>
      <div className="main-page" style={{ display: Display }}>
        <div className="page-container">
          <div className="text-container">
            <div className="app-logo">
              <img
                src="https://raw.githubusercontent.com/AmolShelke2/kredallino/3b146362f3fa4fbb7c66b55eebcb27aeaa99fdd3/src/assets/kred-konnect-logo.svg"
                alt="img"
                height={50}
                width={120}
              />
            </div>
            <h2>Welcome to the new age of Trading {user}</h2>
          </div>

          <div className="cards">
            <div className="register-card">
              <MdOutlineLogin className="card-icon" />
              <div className="text-content">
                <Link to={"/login"}>
                  <h4>Login</h4>
                </Link>
                <p>For existing kredconnect user</p>
              </div>
            </div>
            <div className="register-card">
              <FaUser className="card-icon" />
              <div className="text-content">
                <a
                  href="https://registration.kredkonect.com/"
                  target="_blank"
                  rel="noreferrer">
                  <h4>Open an account</h4>
                </a>
                <p>For new users</p>
              </div>
            </div>
          </div>
          <div className="bottom-container">
            <li>
              <a
                href="https://www.kredallino.tech/"
                target="_blank"
                rel="noreferrer">
                V=0.1.0
              </a>
            </li>
            <li>
              <a href="#">Disclaimer</a>
            </li>
            <li>
              <a
                href="https://shop.kredallino.com/policies/privacy-policy"
                target="_blank"
                rel="noreferrer">
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://shop.kredallino.com/policies/terms-of-service"
                target="_blank"
                rel="noreferrer">
                terms of use
              </a>
            </li>
          </div>
        </div>
      </div>
    </Draggable>
  ) : (
    navigate("/trade")
  );
}
