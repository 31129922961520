import { createAction } from "redux-actions";

const setSearchResultCnst = "SET_SEARCHRESULTS";

export const setSearchResultsAction = createAction(
  setSearchResultCnst,
  (payload) => payload
);

export const searchResultsReducer = (
  state = { selectSearchResult: null },
  action
) => {
  switch (action.type) {
    case setSearchResultCnst:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
