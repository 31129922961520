import { useSelector } from "react-redux";
import { Basket } from "./Basket.jsx";
import { Closed } from "./Closed.jsx";
import { IoIosClose } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { useState } from "react";
import { message } from "antd";
import { formatTime } from "../../utils.js";

export const TabContent = ({ currentTab }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");

  const positions = useSelector((state) => state.positions);
  console.log("orders from redux", positions);
  const orders = positions.orders.orders;

  const pendingOrders = orders.filter((order) => order.status === "PENDING");
  console.log("Pending orders", pendingOrders);

  const handleCancelOrder = async (exchange, orderID) => {
    const data = await window.asthaApi.cancelOrder(exchange, orderID);
    console.log(data);
    await window.asthaApi.getOrders();
  };

  const setModifyOrder = (order) => {
    setIsEditing(true);
    setPrice(order.order_price);
    setQuantity(order.total_quantity);
  };

  const handleModifyOrder = async (exchange, orderID, order) => {
    const modifiedOrderData = {
      quantity: Number(quantity),
      price: Number(price),
    };

    console.log(modifiedOrderData, "by user");

    const data = await window.asthaApi.modifyOrder(
      exchange,
      orderID,
      modifiedOrderData
    );

    message.success(data?.message);
    await window.asthaApi.getOrders();
    setIsEditing(false);
  };

  if (currentTab === "Open") {
    return (
      <div className="open-page">
        {pendingOrders.length !== 0 && pendingOrders ? (
          <div
            className={`open-orders-container ${
              pendingOrders.length > 5 ? "active" : ""
            }`}>
            {pendingOrders?.map((order, indx) => (
              <div className="open-card" key={order.order_id}>
                {/* left */}
                <div className="left">
                  <span className="time">
                    {formatTime(order.order_created_at)}
                  </span>
                  <h3>{order.symbol}</h3>
                  <p>
                    NSE <i>{order.transaction_type}</i> {""}
                    {isEditing ? (
                      <input
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleModifyOrder(order.exchange, order.order_id);
                          }
                        }}
                      />
                    ) : (
                      <span>{order.total_quantity}</span>
                    )}{" "}
                    Shares
                  </p>
                </div>
                <div className="close-edit-container">
                  <IoIosClose
                    size={25}
                    className="close-icon"
                    onClick={() =>
                      handleCancelOrder(order.exchange, order.order_id)
                    }
                  />
                  <CiEdit
                    size={25}
                    className="edit-icon"
                    onClick={() => setModifyOrder(order)}
                  />
                </div>
                {/* right side */}
                <div className="right">
                  <span>LIMIT - CASH</span>
                  {isEditing ? (
                    <input
                      type="number"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleModifyOrder(order.exchange, order.order_id);
                        }
                      }}
                    />
                  ) : (
                    <h3>{order.order_price}</h3>
                  )}
                  <p>{order.status}</p>
                </div>
              </div>
            ))}
            <div className="closed-orders-info">
              <p>This are your open orders from today.</p>
            </div>
          </div>
        ) : (
          <div className="content-page">
            <div className="image-container">
              <img
                src="https://cdn-icons-png.flaticon.com/128/1090/1090965.png"
                alt="shares"
                height={150}
                width={150}
              />
            </div>
            <div className="text-container">
              <p>Your pending orders from today will appear here</p>
            </div>
          </div>
        )}
      </div>
    );
  } else if (currentTab === "Closed") {
    return <Closed />;
  } else {
    return <Basket />;
  }
};
