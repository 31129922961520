import { useNavigate } from "react-router";
import stocksArray from "../components/Trade/options";
import { BsGraphUpArrow } from "react-icons/bs";
import { TbNetwork } from "react-icons/tb";
import { BiNews } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AiOutlineDashboard } from "react-icons/ai";
import { CiStar } from "react-icons/ci";
import { NavigationItems } from "../components/Navigation/NavigationItems.jsx";
import "../styles/Tools.css";
import Draggable from "react-draggable";
import { useDragContext } from "../App.js";

export default function Tools() {
  const navigate = useNavigate();
  const { dragPosition, handleDrag } = useDragContext();

  return (
    <Draggable position={dragPosition} onDrag={handleDrag}>
      <div className="tools-page">
        <div className="page-container">
          {/* option chain */}
          <div className="option-chain">
            <h2>Option chain</h2>
            {/* options container */}
            <div className="options-container">
              {stocksArray.map((stock) => (
                <div
                  className="option-card"
                  key={stock.id}
                  onClick={() => {
                    window.asthaApi.getOptionChain(stock.name.toUpperCase());
                    navigate("/option-chain");
                  }}>
                  <h4>{stock.name}</h4>
                </div>
              ))}
            </div>
          </div>

          {/* Research */}
          <div className="research">
            <h2>Research</h2>
            {/* research cards container */}
            <div className="research-cards">
              {/* each item */}
              <div className="research-card">
                <BsGraphUpArrow />

                <p>Investments</p>
              </div>
              {/* <div className="research-card">
                <BsGraphUpArrow />

                <p>
                  <a
                    href="https://web.telegram.org/k/#@ultrasignalindicator"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#fff" }}>
                    Trading calls
                  </a>
                </p>
              </div> */}
            </div>
          </div>

          {/* Market updates */}
          <div className="market-updates">
            <h2>Market updates</h2>
            {/* research cards container */}
            <div className="market-updates-cards">
              {/* each item */}
              <div className="market-update-card">
                <TbNetwork size={25} className="market-icon" />

                <p>Live news</p>
              </div>
              <div className="market-update-card">
                <BiNews size={25} className="market-icon" />

                <p>short news</p>
              </div>
            </div>
          </div>

          {/* Investing tools */}
          <div className="investing-tools">
            <h2>Investing tools</h2>

            {/* investing tools */}
            <div className="investing-tools-cards">
              {/* each card */}
              <div className="investing-card">
                <Link to={"https://saras-frontend.vercel.app/"} target="_blank">
                  <AiOutlineDashboard size={25} className="investing-icon" />
                  <p>Screeners</p>
                </Link>
              </div>

              <div className="investing-card">
                <CiStar size={30} className="investing-icon" />

                <p>Superstar Investors</p>
              </div>
              <div className="investing-card">
                <BsGraphUpArrow size={25} className="investing-icon" />

                <p>Results dashboard</p>
              </div>
            </div>
          </div>
          {/* navigation items */}
          <NavigationItems />
        </div>
      </div>
    </Draggable>
  );
}
