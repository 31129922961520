import React, { useState, useRef } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import Draggable from "react-draggable";
import {
  IronEdgeLogin,
  getToken,
} from "../../external_js/html_popup";
import config from "../../config.json"
import { Alert, Space, Button, message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setProfileAction } from "../../redux/profile/setProfileAction";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useDragContext } from "../../App";

export const LoginComponent = () => {
  console.log("app name from config",config.appName)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dragPosition, handleDrag } = useDragContext();

  const [username, setUsername] = useState(
    useSelector((state) => state.profile.clientCode)
  );

  const [password, setPassword] = useState(
    useSelector((state) => state.profile.password)
  );

  const [loading, setLoading] = useState(false);
  const [OTP, setOTP] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const profile = {
    name: username || "Stark",
    token: password || "123456",
  };

  const setClient = async () => {
    setLoading(true);

    try {
      dispatch(setProfileAction({ clientCode: username, password: password }));
      var response = await IronEdgeLogin();
      console.log("response from IronEdgeLogin", response);
      if (response?.length > 0 && response[0]?.status === "success") {
        message.success("Login Successful");
        navigate("/trade");
      } else {
        message.error("Login Failed " + response?.detail || "Unknown");
      }
    } catch (error) {
      console.error("Error during login:", error);
      message.error("An error occurred during login " + response?.detail || "Unknown");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Draggable position={dragPosition} onDrag={handleDrag}>
      <div className="login-page">
        <div className="login-text-container">
          <div className="app-logo">
            <img
              src={config.loginPage.logoUrl}
              alt={config.loginPage.logoAltText}
              height={config.loginPage.logoHeight}
              width={config.loginPage.logoWidth}
            />
          </div>
          <h2 className="welcome-text">
            Hi 👋
            <br /> <span>Welcome Back to {config.appName}</span>
          </h2>
        </div>

        {loading && (
          <div className="loader-container">
            <Spin size="large" />
          </div>
        )}

        <div className="form-container">
          <input
            type="text"
            className="input-item"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder={config.loginPage.inputPlaceholders.username}
          />

          <div className="password-input">
            <input
              type={showPassword ? "text" : "password"}
              placeholder={config.loginPage.inputPlaceholders.password}
              className="input-item"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            {showPassword ? (
              <BsEyeSlash className="eye-icon" onClick={toggleShowPassword} />
            ) : (
              <BsEye className="eye-icon" onClick={toggleShowPassword} />
            )}
          </div>

          <button
            type="submit"
            className="login-btn"
            onClick={setClient}
            disabled={loading}>
            {config.loginPage.loginButtonText}
          </button>
        </div>
        <div className="bottom-container">
          {config.loginPage.links.map((link, index) => (
            <li key={index}>
              <a
                href={link.url}
                target="_blank"
                rel="noreferrer">
                {link.text} {link.displayVersion ? config.appVersion : ""}
              </a>
            </li>
          ))}
        </div>
      </div>
    </Draggable>
  );
};