import { createAction } from 'redux-actions';
import _ from 'lodash'; // lodash library for deep cloning

export const setProfileRdxConst = "SET_PROFILE"
export const setTokenRdxConst = "SET_TOKEN"

export const setProfileAction = createAction(setProfileRdxConst, (payload) => payload);
export const setTokenAction = createAction(setTokenRdxConst, (payload) => payload);


export const setProfileReducer = (state = { clientCode: null, password: null, tempToken: null, }, action) => {
    switch (action.type) {
        case setProfileRdxConst:
           return { ...state, ..._.cloneDeep(action.payload) };
        case setTokenRdxConst:
           return { ...state, ..._.cloneDeep(action.payload) };
        default:
            return state;
    }
}

