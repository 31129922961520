// This File Contains code adding event to elements and some other function for extra functionality
/* eslint-disable */
import * as helper from "./helper";
import * as opt from "../external_js/opt";
import * as html_popup from "../external_js/html_popup";
import AsthaApi from "./apiCalls";
import { CONNECTING } from "ws";
import { message } from "antd";
import { appStore } from "../redux/store";
import { playSound } from "./helper";

export async function checkUserId(userIdName = "userId") {
  let userId = await getData(userIdName);
  console.log("user id", userId);
  console.log("user id", userId);
  if (Object.keys(userId).length === 0) {
    alert("Login First To Use This Extension");
    return false;
  }
  alert("User Id Found", userId);
  return true;
}

export async function elementEvents() {
  $(".drag").draggable();

  html_popup.html_popup_function();



  $(".drag").draggable();


  // $("#small-option-chain").on("click", () => {
  //   let optionName = $("#small-option-chain").attr("data-optionSymbol");
  //   helper.getOptionChain(optionName);
  // });

  // $("input[name='option-chain-radio']").on("change", (e) => {
  //   let optionName = e.target.value;
  //   helper.getOptionChain(optionName);
  // });

  // $("#expiry-date").on("change", (e) => {
  //   let optionName = $("#option-name").html();
  //   helper.getOptionChain(optionName, e.target.value);
  // });

  $("");


  // function created to add event listener to button's and ic  on's
  $("#buyButton").on("click", function (event) {
    event.preventDefault();
    upArrowHandler();
  });

  $("#sellButton").on("click", function (event) {
    event.preventDefault();
    downArrowHandler();
  });



  $("#quantity").on("input", function () {
    console.log("quantity changed");
    helper.updateTotal();
  });

  $("#greek-toogle").on("click", function () {
    $(".greek").fadeToggle(1);
    $(".non-greek").fadeToggle(1);
  });

  $("#liveLtp").on("click", () => {
    let currentToken = document.querySelector("#token").value;
    $("#price").toggleClass(currentToken);
  });
  await helper.initTSL();

  // $(".option-chain-button").on("click", function () {
  //   $(".option-chain").fadeToggle(1);
  //   if ($(".option-chain").css("display") == "block") {
  //     helper.getOptionChain();
  //   }
  //   // $("#crypto-modal").css({ "z-index": "99999" })
  // });
  // notification button click

  // hide button

  document.addEventListener("click", function (event) {
    if (
      event.target.tagName === "OPTION" &&
      event.target.parentNode.tagName === "DATALIST"
    ) {
      // Event handling code here
      console.log("Clicked on option:", event.target.value);
    }
  });

  // when quick access button is clicked
  $("input[type=radio][name='quickSelector']").on("click", function () {
    timer();
  });
  $("button.auto-strike-pe").on("click", function () {
    timer("pe");
  });
  $("button.auto-strike-ce").on("click", function () {
    timer("ce");
  });
  // when search in input box is clicked
  $("#symbol").on("input", helper.searchHandler); // this function is provided by the backend broker
  helper.selectSearchList("ul.search-list");
}

export function keyboardEvent() {
  // function used to handle keyboard events and other events also
  document.addEventListener("keydown", function (e) {
    // when ctrl + i key is pressed
    if ($("main.trade-dialog").css("display") == "block") {
      // some keyboard shortcuts are only available when trade dialog is open
      console.log("key event", e.ctrlKey, e.which);

      if (e.ctrlKey && e.shiftKey && e.which == 37) {
        // when ctrl + shift + up arrow is pressed
        timer("PE", "Nifty Bank");
      } else if (e.ctrlKey && e.shiftKey && e.which == 38) {
        // when ctrl + shift + down arrow is pressed
        timer("CE", "Nifty Bank");
      } else if (e.ctrlKey && e.shiftKey && e.which == 39) {
        // when ctrl + shift + left arrow is pressed
        timer("PE", "Nifty 50");
      } else if (e.ctrlKey && e.shiftKey && e.which == 40) {
        // when ctrl + shift + left arrow is pressed
        timer("CE", "Nifty 50");
      } else if (e.ctrlKey && e.shiftKey && e.which == 70) {
        // when ctrl + shift + f is pressed
        $("#symbol").trigger("focus");
      } else if (e.which == 38) {
        console.log("up arrow key pressed");
        e.preventDefault();

        upArrowHandler();
      } else if (e.which == 40) {
        console.log("down arrow key pressed");
        e.preventDefault();
        downArrowHandler();
      }
    }
    // ctrl + i is pressed
    if (e.ctrlKey && e.which == 73) {
      console.log("ctrl + i key pressed");
      $(".trade-dialog").fadeToggle(1);
    }
  });

  $("#symbol").on("keypress", function (e) {
    if (e.which === 13) {
      // Call your function here
      $($(".search-list a")[0]).trigger("click");
    }
  });
}

export function formData() {
  // function to convert form entries to js object and to convert required data type
  let form_data = Object.fromEntries(form.entries());
  form_data["price"] = parseFloat(form_data["price"]);
  form_data["token"] = parseInt(form_data["token"]);
  form_data["validity_days"] = parseInt(form_data["validity_days"]);
  form_data["disclosed_quantity"] = parseInt(form_data["disclosed_quantity"]);
  form_data["trigger_price"] = parseFloat(form_data["trigger_price"]);
  form_data["is_amo"] = JSON.parse(form_data["is_amo"]);
  form_data["quantity"] = parseInt(form_data["quantity"]);
  if (form_data["exchange"] === "NSE_FO") {
    console.log("updaitng lot size");
    let lot_size = $("#lotSize").html();
    form_data["quantity"] = form_data["quantity"] * lot_size;
    console.log("new quantity", form_data["quantity"], "lot size", lot_size);
  }
  return form_data;
}

export async function upArrowHandler(form_data) {
  playSound();
  // function used to used handle up arrow key event and buy button press
  console.log("form data", form_data);
  let asthaApi = window.asthaApi;
  let response = await asthaApi.placeOrder(form_data);
  console.log("buy order response", response);
  message.success(response?.message);
  // helper.playSound()
  playSound();
}

export async function downArrowHandler(form_data) {
  // function used to handle down arrow key event
  playSound();
  console.log("form data", form_data);
  let asthaApi = window.asthaApi;
  let response = await asthaApi.placeOrder(form_data);
  console.log("sell order response", response);
  message.success(response?.message);
}

export function ToggleVisiblity() {
  $(".trade-dialog").fadeToggle(1);
}

export async function Message(
  messageType = "#FF9800",
  MessageText = "Message"
) {
  // function used to display message
  let currentTime = Date.now();
  let messageDiv =
    $(`<div id="alert-${currentTime}" class="trade-x flex items-center p-4 mb-4 text-blue-800 border-t-4 border-blue-700 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800" role="alert">
  <svg class="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
  <div class="ml-3 text-sm font-medium">
    ${MessageText}
  </div>
  <button type="button"  data-dismiss-target="#alert-${currentTime}"  id="button-${currentTime}" data-model-toggle="alert-${currentTime}" class=" trade-x Close ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5  inline-flex h-8 w-8 dark:bg-gray-800 dark:text-yellow-300 dark:hover:bg-gray-700"  aria-label="Close">
    <span class="sr-only">Dismiss</span>
    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
  </button>
 </div>`);

  $("#notification-container").append(messageDiv);

  setTimeout(() => {
    hideMessage(currentTime);
  }, 8000);

  console.log("button element", $(`#button-${currentTime}`));
  $(`#button-${currentTime}`).on("click", function () {
    console.log("close button clicked");
    hideMessage(currentTime);
  });
}

export async function hideMessage(id) {
  let uniqueId = $("#alert-" + id).hide();
}

export async function increaseWidth(selector, value = 5) {
  console.log("increase width function selector value", selector);
  let div = $(selector);
  console.log("selector", div);
  let width = parseFloat(div.css("width"));
  console.log("with", width);
  let newWidth = width + value;
  div.css("width", newWidth + "px");
}

export async function decreaseWidth(selector, value = 5) {
  let div = $(selector);
  let width = parseFloat(div.css("width"));
  let newWidth = width - value;
  div.css("width", newWidth + "px");
}

export async function timer(
  optionType = "PE",
  searchText = "",
  checkFilter = true
) {
  console.log("clicked");
  if (!searchText) {
    searchText = $("input[name=quickSelector]:checked").val();
  }
  console.log("searched text", searchText);

  $("input[name=optionType]:checked").val();
  console.log("searched text and option type", searchText, optionType);
  let filterText = parseFloat($("#filter-text").val());

  let selector = searchText.replaceAll(" ", "\\ ");
  console.log("searched text updated", searchText);
  let element = $("#" + selector).html();
  console.log("strike price", element);
  let strikePrice = parseInt($("#" + selector).html());

  switch (searchText) {
    case "Nifty Bank":
      // earlier it was 50 round off for banknifty
      strikePrice = Math.round(strikePrice / 100) * 100;
      console.log("Nifty Bank");
      break;
    case "Nifty 50":
      strikePrice = Math.round(strikePrice / 100) * 100;
      console.log("Nifty");
      break;
  }
  console.log("strike price after round off", strikePrice);
  searchText =
    $("#" + selector).attr("data-search") +
    " " +
    strikePrice +
    " " +
    optionType;
  console.log("search term", searchText);
  let response = await window.asthaApi.symbolSearch(searchText);
  console.log("search response from astha api", response);
  let exchange = response[0]._source.ExchangeSegment;
  let token = response[0]._source.nToken;
  let data = response[0];
  let complete_name = `${data._source.sSymbol} ${data._source.nStrikePrice1} ${data._source.nExpiryDate1} ${data._source.sOptionType}`;
  let quantity = data._source.nRegularLot;
  console.log("exchange", exchange, token, complete_name);
  // helper.updateSearchList(response)
  helper.InsertSymbolInfo(token, exchange, complete_name, quantity);
  // console.log("trd-symbol", symbol)
  // $("#symbol").val(symbol)
  // console.log($("#symbol").val())
  // $("#quantity").val(response.data.scrips[0].lotSize)
  // $("#exchange").val("nse_fo")
}

export async function initTSL() {
  // function used to intialize the TSL
  console.log("Inside init TSL");
  if (!window.TSL) {
    window.TSL = {};
  }
  let position_data = await window.asthaApi.positions();
  let position_data_array = position_data?.data?.day;
  // position_data_array =
  //   [
  //     {
  //       "exchange": "NSE_EQ",
  //       "symbol": "Adani Ka Bhai",
  //       "expiry_date": "",
  //       "option_type": "",
  //       "token": 1660,
  //       "product": "MTF",
  //       "quantity": 1,
  //       "overnight_buy_value": 0.00,
  //       "overnight_sell_value": 0.00,
  //       "overnight_average_price": 0.00,
  //       "lot_size": 1,
  //       "multiplier": 1.00,
  //       "average_price": 380.00,
  //       "value": -380.00,
  //       "buy_value": 380.00,
  //       "sell_value": 0.00,
  //       "buy_quantity": 1,
  //       "sell_quantity": 0,
  //       "buy_price": 380.00,
  //       "sell_price": 0.00,
  //     },
  //     {
  //       "exchange": "NSE_EQ",
  //       "symbol": "Ambani ka Bhai",
  //       "expiry_date": "",
  //       "option_type": "",
  //       "token": 123,
  //       "product": "MTF",
  //       "quantity": 1,
  //       "overnight_buy_value": 0.00,
  //       "overnight_sell_value": 0.00,
  //       "overnight_average_price": 0.00,
  //       "lot_size": 1,
  //       "multiplier": 1.00,
  //       "average_price": 380.00,
  //       "value": -380.00,
  //       "buy_value": 380.00,
  //       "sell_value": 0.00,
  //       "buy_quantity": 1,
  //       "sell_quantity": 0,
  //       "buy_price": 380.00,
  //       "sell_price": 0.00,
  //     },
  //     {
  //       "exchange": "NSE_EQ",
  //       "symbol": "Musk ka Chacha",
  //       "expiry_date": "",
  //       "option_type": "",
  //       "token": 458,
  //       "product": "MTF",
  //       "quantity": 1,
  //       "overnight_buy_value": 0.00,
  //       "overnight_sell_value": 0.00,
  //       "overnight_average_price": 0.00,
  //       "lot_size": 1,
  //       "multiplier": 1.00,
  //       "average_price": 380.00,
  //       "value": -380.00,
  //       "buy_value": 380.00,
  //       "sell_value": 0.00,
  //       "buy_quantity": 1,
  //       "sell_quantity": 0,
  //       "buy_price": 380.00,
  //       "sell_price": 0.00,
  //     },
  //   ]
  if (position_data_array.length === 0) {
    return;
  }
  position_data_array.forEach((position) => {
    window.TSL[position["token"]] = {
      target: 0,
      stoploss: 0,
      checkTsl: false,
      trail: 0,
    };
  });
  console.log("TSL", window.TSL);
}

export async function handleTSL(
  token,
  ltp,
  target,
  stoploss,
  positionData,
  trailPoint,
  trailPointSL
) {
  // function used to handle tsl
  const nextPositionType = positionData.quantity > 0 ? "SELL" : "BUY";
  target = parseInt(target);
  stoploss = parseInt(stoploss);
  trailPoint = parseInt(trailPoint);
  trailPointSL = parseInt(trailPointSL);

  const orderData = {
    exchange: positionData.exchange,
    token: positionData.token,
    transaction_type: nextPositionType,
    product: "INTRADAY",
    variety: "RL",
    quantity: Math.abs(positionData.quantity),
    price: target,
    trigger_price: target,
    disclosed_quantity: 0,
    validity: "DAY",
    validity_days: 1,
    is_amo: false,
  };

  console.log("inside handle tsl", token, target, stoploss, ltp);
  console.log("ltp > target", ltp > target, "stoploss < ltp", stoploss < ltp);
  if (!target || !stoploss) {
    console.log("target or stoploss not found");
    return;
  }
  if (token && target != -1 && stoploss != -1) {
    if (ltp >= target) {
      // when target is hit , place a reversed order
      message.success("Target Hit  For" + positionData.symbol);

      console.log("last trade price", ltp, "target", target);
      orderData.price = target;
      orderData.trigger_price = 0;
      await window.asthaApi.placeOrder(orderData);
      // to clear old tsl data
      const oldTSL = appStore.getState().tsl;
      oldTSL[token] = {};
      await appStore.dispatch({ type: "SET_TSL", payload: oldTSL });
    } else if (ltp <= stoploss) {
      // when stoploss is hit , place a reversed order
      message.success("StopLoss Hit  For " + positionData.symbol);
      console.log("last trade price", ltp, "stoploss", stoploss);
      orderData.price = stoploss;
      orderData.trigger_price = 0;
      await window.asthaApi.placeOrder(orderData);
      // to clear old tsl data
      const oldTSL = appStore.getState().tsl;
      oldTSL[token] = {};
      appStore.dispatch({ type: "SET_TSL", payload: oldTSL });
    } else if (ltp >= positionData.buy_price + trailPoint) {
      // To Trail Target
      console.log("Trail Target for Token", token, "old target", target);
      target += trailPointSL;
      console.log("new target", target);
      const oldTSL = appStore.getState().tsl;
      oldTSL[token].target = target;
      appStore.dispatch({ type: "SET_TSL", payload: oldTSL });
      message.success("Target Trail For " + positionData.data.symbol);
    } else if (positionData.sell_price) {
      // To Trail Stoploss
      console.log("Nothing to trail stoploss");
    }
  }
}
