import { createAction } from "redux-actions";
import _ from "lodash"
export const setPositionsRdxConst = "SET_ORDER_BOOK";

export const setPositionsAction = createAction(setPositionsRdxConst, (payload) => payload);

export const setPositionsReducer = (state = [], action) => {
    switch (action.type) {
        case setPositionsRdxConst:
            return { ...state, ..._.cloneDeep(action.payload) };
        default:
            return state;
    }
};

// Path: react_app/src/redux/funds/setFundsAction.js