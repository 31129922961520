import { message } from "antd";
import { appStore } from "../redux/store";
import { setPnlAction } from "../redux/pnl/setPnlAction";
import { deleteTSLAction, setTSLAction } from "../redux/Tsl/setTslAction";
import { upArrowHandler, downArrowHandler, handleTSL } from "../js/eventAdder";

var JSONbig = require("json-bigint");

var VortexFeed = require("@asthatrade/jsvortex").VortexFeed;
var Constants = require("@asthatrade/jsvortex").Constants;
var wire = new VortexFeed("no token", true, true);

const orderFormData = {
  exchange: "NSE_EQ",
  product: "INTRADAY",
  variety: "RL-MKT",
  price: 0,
  quantity: 1,
  validity: "DAY",
  validity_days: 1,
  is_amo: false,
  trigger_price: 0,
  disclosed_quantity: 0,
  transaction_type: "BUY",
  token: "",
};

export default function startWebsocket(access_token) {
  console.log("startWebsocket");
  wire = new VortexFeed(access_token, true, true);
  wire.on("price_update", onPriceUpdate);
  wire.on("order_update", onOrderUpdate);
  wire.on("error", onError);
  wire.on("close", onClose);
  wire.on("connect", onConnect);
  wire.connect();
  wire.subscribe(Constants.ExchangeTypes.NSE_EQUITY, 26000, "full");
  wire.subscribe(Constants.ExchangeTypes.NSE_EQUITY, 26009, "full");
  wire.subscribe(Constants.ExchangeTypes.NSE_EQUITY, 26037, "full");
  window.wire = wire; // make it global
  console.log("wire connected");
  return wire;
}

function onConnect() {
  console.log("websocket connected");
  wire.subscribe(Constants.ExchangeTypes.NSE_EQUITY, 26000, "full");
  wire.subscribe(Constants.ExchangeTypes.NSE_EQUITY, 26037, "full");
  wire.subscribe(Constants.ExchangeTypes.NSE_EQUITY, 26009, "full");
  console.log("token subscribed");
}

function onPriceUpdate(updates) {
  // console.log("price update from web socket", JSONbig.stringify(updates))

  // loop to update the ltp price
  updates.map((item) => {
    // console.log("item", item.exchange, item.token, item.last_trade_price, item.last_trade_time);
    // firts loop to update the ltp price
    var elements = document.querySelectorAll(`.ltp-${item.token}`);

    elements.forEach((element) => {
      const lastPrice = parseFloat(element.textContent);
      const newPrice = parseFloat(item.last_trade_price);

      if (newPrice > lastPrice) {
        // If going up, change color to green
        element.style.color = "rgb(78, 182, 7)";
      } else if (newPrice < lastPrice) {
        // If going down, change color to red
        element.style.color = "rgb(253, 127, 127)";
      }

      element.textContent = item.last_trade_price;
    });

    // second loop to update the change price
    elements = document.querySelectorAll(".market-change-" + item.token);

    elements.forEach((element) => {
      const priceChange = (item.last_trade_price - item.close_price).toFixed(2);
      const percentChange = ((priceChange / item?.close_price) * 100).toFixed(
        2
      );

      element.textContent = `${priceChange} (${percentChange}%)`;

      if (priceChange && percentChange < 0) {
        element.style.color = "rgb(253, 127, 127)";
      } else {
        element.style.color = "rgb(78, 182, 7)";
      }
    });

    var pnlObject = appStore.getState().pnl.pnl; // an object
    const positions = appStore.getState().positions;
    // console.log("positions from redux store", positions)
    positions.positions.data.day.map((position) => {
      if (position?.token === item?.token) {
        pnlObject[item.token] = {
          pnl: (item.last_trade_price - position.buy_price) * position.quantity,
          token: position.token,
          percentChange:
            ((item.last_trade_price - position.buy_price) /
              position.buy_price) *
            position.quantity *
            100,
        };
      } else {
        // console.log("token not found in positions", position?.token, item?.token)
        // window.wire.subscribe(position?.exchange, position?.token, "full")
      }
    });

    // third loop to update the pnl
    // console.log("Pnl Object", pnlObject)
    appStore.dispatch(setPnlAction({ pnl: pnlObject }));
    // console.log("state pnl", appStore.getState().pnl)
  });
  const tslData = appStore.getState().tsl;
  // console.log("position data from tsl store", tslData)

  updates.map((item) => {
    if (tslData[item.token]) {
      handleTSL(item.token, item.last_trade_price, tslData[item.token].target, tslData[item.token].Stoploss, tslData[item.token].data, tslData[item.token].trail_point, tslData[item.token].trail_point_sl);
    }
  });
}

async function onOrderUpdate(update) {
  update = JSON.parse(update);
  console.log("order update from websocket", update?.data?.status, "udpate", update);
  message.info("Order Update " + update?.data?.status);
  if (update?.data?.type === "trade") {
    await window.asthaApi.getOrders();
  }
  else { 
    await window.asthaApi.getFunds();
    await window.asthaApi.positions();
  }
}

function onClose(reason) {
  console.log("websocket connection closed", reason);
  // message.error("WebSocket Connection Closed " + reason)
}

function onError(reason) {
  console.log("websocket connection error", reason);
}

// startWebsocket(access_token)
