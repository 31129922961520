import { createAction } from "redux-actions";
export const setOrderBooksRdxConst = "SET_ORDER_BOOK";

export const setOrderBooksAction = createAction(setOrderBooksRdxConst, (payload) => payload);

export const setOrderBooksReducer = (state = [], action) => {
    switch (action.type) {
        case setOrderBooksRdxConst:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

// Path: react_app/src/redux/funds/setFundsAction.js