import React, { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { CiWallet, CiStar } from "react-icons/ci";
import stocksArray, { marketIndices } from "./options";

import { useSelector, useDispatch } from "react-redux";
import { Pnl } from "../pnl/pnl.jsx";
import "./Trade.css";
import Draggable from "react-draggable";
import { Link } from "react-router-dom";
import { NavigationItems } from "../Navigation/NavigationItems.jsx";
import { AiOutlineDashboard, AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
// import { upArrowHandler, downArrowHandler } from "../../../../js/eventAdder.js";
import { upArrowHandler,downArrowHandler} from "../../js/eventAdder.js"
import {autoStrike} from "../../external_js/html_popup.js"
import { setOrderDataAction } from "../../redux/orderData/setOrderDataAction.js";
import { KeyBoardShortcut } from "./KeyBoardShortcut.jsx";
import { useDragContext } from "../../App.js";
import { message } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function TradeComponent() {
  const dispatch = useDispatch();
  const { dragPosition, handleDrag } = useDragContext();
  const notificationsRdx = useSelector(
    (state) => state.notifications.notifications
  );

  const searchResultCard = useSelector((state) => state.selectSearchResult);
  const orderData = useSelector((state) => state.orderData);
  console.log(orderData);
  const selectedSearchCard = searchResultCard.selectSearchResult;
  console.log("selectedSearchCard from REDUX", selectedSearchCard);
  const [LTP, setLTP] = useState(0);

  const [formData, setFormData] = useState({
    exchange: selectedSearchCard?._source.ExchangeSegment,
    product: "INTRADAY",
    token: selectedSearchCard?._source.nToken,
    variety: "RL-MKT",
    price: 0,
    quantity: selectedSearchCard?._source.quantity,
    validity: "DAY",
    validity_days: 1,
    is_amo: false,
    trigger_price: 0,
    disclosed_quantity: 0,
    transaction_type: "BUY",
  });

  useEffect(() => {
    const tradesPage = document.querySelector(".trades-page");
    if (tradesPage) {
      tradesPage.focus();
    }
    window.asthaApi.getNotifications();
  }, []);

  const handleOrderInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleBuyOrderPlace = () => {
    const updatedFormData = {
      ...formData,
      transaction_type: "BUY",
    };
    upArrowHandler(updatedFormData);
  };

  const handleSellOrderPlace = () => {
    const updatedFormData = {
      ...formData,
      transaction_type: "SELL",
    };
    downArrowHandler(updatedFormData);
  };

  useEffect(() => {
    window.asthaApi
      .getLtp(
        selectedSearchCard?._source.ExchangeSegment,
        selectedSearchCard?._source.nToken
      )
      .then((ltp) => {
        setLTP(ltp);
        console.log({ ltp });
      });
  }, [selectedSearchCard?._source.nToken]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      exchange: selectedSearchCard?._source.ExchangeSegment,
      token: selectedSearchCard?._source.nToken,
      quantity: selectedSearchCard?._source.quantity,
    }));
    dispatch(setOrderDataAction({ orderData: formData }));
  }, [selectedSearchCard]);

  console.log("useState form Data", formData);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 38) {
        // Up arrow key
        console.log("up arrow key pressed");
        handleBuyOrderPlace();
        event.preventDefault();
      } else if (event.keyCode === 40) {
        // Down arrow key
        console.log("down arrow key pressed");
        handleSellOrderPlace();
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [formData]);

  console.log("Notification from Redux", notificationsRdx);

  useEffect(() => {
    notificationsRdx?.forEach((notification) => {
      if (!notification.is_read) {
        message.success(notification.content);
      }
    });
  }, []);

  return (
    <Draggable position={dragPosition} onDrag={handleDrag}>
      <div className="trades-page">
        <div className="page-container">
          <div className="trades-header">
            {/* input container */}
            <div className="input-div">
              <IoSearchOutline className="search-icon" size={20} />
              <Link to={"/search-results"}>
                <input type={"text"} placeholder="Search shares, stocks, .." />
              </Link>

              <Link to={"/profile"}>
                <AiOutlineMenu className="menu-icon-trade" size={20} />
              </Link>
            </div>
            <Pnl />
          </div>

          {/* market indices */}
          <div className="indices">
            <div className="market-cards-container">
              {/* card */}
              {marketIndices.map((market) => (
                <div className="market-card" key={market.id}>
                  <h4>{market.name}</h4>
                  <h5 className={`ltp-${market?.token}`}>{market.value}</h5>
                  <p className={`market-change-${market?.token}`}></p>
                  <button
                    className="ce-btn"
                    onClick={() => {
                      autoStrike(market.name.toLowerCase(), "PE");
                    }}>
                    PE
                  </button>
                  <button
                    className="pe-btn"
                    onClick={() => {
                      autoStrike(market.name.toLowerCase(), "CE");
                    }}>
                    CE
                  </button>
                </div>
              ))}
            </div>
          </div>
          {/* quick order */}
          <div className="quick-order">
            {/* <p className="order-text">quick order window</p> */}
            {/* price/ltp */}
            <div className="price-ltp">
              <h5>
                {selectedSearchCard?._source.sSecurityDesc} @ {}
              </h5>
              <span>₹ {LTP} </span>
              <KeyBoardShortcut />
            </div>
            {/* information-container */}
            <div className="orders-information-container">
              <div className="information-row">
                <p>Product Series</p>
                <span>:</span>
                <select
                  className="select-dropdown"
                  value={formData.product}
                  onChange={(e) =>
                    handleOrderInputChange("product", e.target.value)
                  }>
                  <option value="INTRADAY">Intraday</option>
                  <option value="DELIVERY">Delivery</option>
                  <option value="MTF">mtf</option>
                </select>
              </div>
              <div className="information-row">
                <p>Order Price</p>
                <span>:</span>
                <input
                  type="number"
                  className="input"
                  value={formData.price || LTP}
                  onChange={(e) =>
                    handleOrderInputChange("price", Number(e.target.value))
                  }
                />
              </div>
              <div className="information-row">
                <p>qty/lot size</p>
                <span>:</span>
                <input
                  className="input"
                  type="number"
                  value={
                    formData.quantity || selectedSearchCard?._source.quantity
                  }
                  onChange={(e) =>
                    handleOrderInputChange("quantity", Number(e.target.value))
                  }
                />
              </div>
              <div className="information-row">
                <p>variety</p>
                <span>:</span>
                <select
                  defaultValue={formData.variety}
                  className="select-dropdown"
                  onChange={(e) =>
                    handleOrderInputChange("variety", e.target.value)
                  }>
                  <option value="RL">REGULAR</option>
                  <option value="RL-MKT">RE-MKT</option>
                  <option value="SL">STOP LOSS</option>
                  <option value="SL-MKT">SL-MKT</option>
                </select>
              </div>
              <div className="information-row">
                <p>AMO</p>
                <span>:</span>
                <select
                  className="select-dropdown"
                  value={formData.is_amo}
                  onChange={(e) =>
                    handleOrderInputChange("is_amo", Boolean(e.target.value))
                  }>
                  <option value={true}>YES</option>
                  <option value={false}>NO</option>
                </select>
              </div>
              <div className="information-row">
                <p>Place order</p>
                <span>:</span>
                <div className="btns">
                  <button className="buy-btn" onClick={handleBuyOrderPlace}>
                    Buy
                  </button>
                  <button className="sell-btn" onClick={handleSellOrderPlace}>
                    sell
                  </button>
                </div>
              </div>
            </div>
            {/* auto strike */}
            {/* <div className="auto-strike">
              <p>Auto strike</p>
              <div className="auto-strike-btns">
                <button className="call-btn">
                  <IoMdArrowUp className="arrow-icon" />
                  Call
                </button>
                <button className="put-btn">
                  <IoMdArrowDown className="arrow-icon" />
                  Put
                </button>
              </div>
            </div> */}
          </div>
        </div>
        {/* navigation items */}
        <NavigationItems />
      </div>
    </Draggable>
  );
}
