import { createAction } from "redux-actions";

const setTSLCnst = "SET_TSL";
const deleteTSLCnst = "DELETE_TSL";

export const setTSLAction = createAction(setTSLCnst, (payload) => payload);
export const deleteTSLAction = createAction(deleteTSLCnst, (id) => id);

export const tslReducer = (
  state = {
    123: {},
  },
  action
) => {
  switch (action.type) {
    case setTSLCnst:
      return { ...state, ...action.payload };
    case deleteTSLCnst:
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    default:
      return state;
  }
};
